<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import { TagsInputItemDelete, type TagsInputItemDeleteProps, useForwardProps } from 'radix-vue'
import { Icon } from '@iconify/vue'

const props = defineProps<TagsInputItemDeleteProps & { class?: HTMLAttributes['class'] }>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <TagsInputItemDelete v-bind="forwardedProps" :class="['tags-input-item-delete', props.class]">
    <slot>
      <Icon icon="radix-icons:cross-2" class="icon" />
    </slot>
  </TagsInputItemDelete>
</template>

<style scoped>
.tags-input-item-delete {
  display: flex;
  border-radius: var(--radius);
  background-color: transparent;
  margin-right: 0.25rem;
}

.icon {
  width: 1rem;
  height: 1rem;
}
</style>