<script async setup lang="ts">
import ButtonSecondary from '@app/components/common/ButtonSecondary.vue';
import ButtonPrimary from '@app/components/common/ButtonPrimary.vue';
import Icon from '@app/components/common/Icon.vue';
import ModalShell from '@app/components/common/ModalShell.vue';
import { ref } from '@vue/reactivity';
import Field from '@app/components/common/Field.vue';
import { createTenant } from '@app/state/tenant';

const showModal = ref(false)
const tenantName = ref('')
const tenantSlug = ref('')
const host = ref(window.location.host)

const create = () => {

  createTenant(tenantName.value, tenantSlug.value)
  showModal.value = false
  tenantName.value = ''
  tenantSlug.value = ''
}
</script>

<template>
    <div class="add-tenant-container">
        <ButtonSecondary class="add-tenant" @click="showModal = true">
            <Icon name="plus" color="normal" />
            Create
        </ButtonSecondary>
    </div>
    <ModalShell size="medium" @hide="showModal = false" v-if="showModal" @close="showModal = false">
      Please enter the new tenant details below.
      <div class="form-group">
        <Field class="field" v-model="tenantName" placeholder="Customer Name" />
        <div v-if="tenantName">I want to create a tenant for <span class="accent">{{ tenantName }}</span></div>
      </div>
      <div class="form-group">
        <Field class="field" v-model="tenantSlug" placeholder="customer-slug" />
        <div v-if="tenantSlug">It will be accessible at <span class="accent">{{ host }}/t/{{ tenantSlug }}</span></div>
      </div>
      <ButtonPrimary @click="create" v-if="tenantName && tenantSlug" class="create-tenant">I know what I'm doing</ButtonPrimary>
    </ModalShell>
</template>

<style lang="scss" scoped>
.add-tenant-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.add-tenant {
    margin-top: 40px;
}

.create-tenant {
  width: 50%;
  margin-top: 10px;
}

.accent {
  color: var(--primary-color);
}

.field {
  width: 80%;
}

.form-group {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>