<script async setup lang="ts">
import { Button } from "@app/components/ui/button"
import { Icon } from "@iconify/vue"
import { TOKEN, bind } from "@app/state/auth"
import { API, slug, version } from "@app/state/common"

const auth = async () => {
  if (!slug) throw new Error("Slug is required")
  const { url, verifier } = await API("/auth/oidc/redirect", { slug, version })
  sessionStorage.setItem("oidc_verifier", verifier)
  window.location.href = url
}
</script>

<template>
  <Button variant="outline" type="button" @click="auth">
    <Icon icon="lucide:shield-check" class="mr-2 h-4 w-4" />
    Sign in with SSO
  </Button>
</template>
