<script lang="ts" setup>
import AddTenant from "@app/components/admin/AddTenant.vue";
import Tenants from "@app/components/admin/Tenants.vue";
import AuthBox from "@app/components/auth/AuthBox.vue";
import CommandBar from "@app/components/common/CommandBar.vue";
import Error from "@app/components/common/Error.vue";
import { isBound } from "@app/state/auth";
</script>

<template>
  <div class="home">
    <KeepAlive>
      <Suspense>

        <div class="wrapper">
          <CommandBar />
          <Error />

          <AuthBox />

          <div class="admin" v-if="isBound">
            <AddTenant />
            <Tenants />
          </div>

        </div>
        <template #fallback>
          Loading...
        </template>
      </Suspense>
    </KeepAlive>
  </div>
</template>



<style lang="scss" scoped>
.wrapper {
  height: 100%;
  overflow: hidden;
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  flex-direction: column;
  overflow: auto;
}

.admin {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.left {
  height: 100%;
  overflow: hidden;
  min-width: 35%;
  border-right: 2px solid var(--primary-background-color);
}

.right {
  height: 100%;
  overflow: hidden;
  min-width: calc(65% - 5px);
  width: -webkit-fill-available;
  max-width: 100%;
}

.home {
  width: 100%;
  height: 100%;

  display: inline-flex;
}
</style>
