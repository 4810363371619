<script setup async lang="ts">
import { error } from '@app/state/common'
import Icon from '@app/components/common/Icon.vue';
import { watch, ref } from 'vue';

const fade = ref(false)

watch(error, (value) => {
  if (value) {
    setTimeout(() => {
      fade.value = true
      setTimeout(() => {
        error.value = null
        fade.value = false
      }, 5000)
    }, 5000)
  }
})
</script>

<template>
  <div v-if="error" :class="['error', { fade }]">
    <Icon name="danger" color="red" />
    {{ error }}
  </div>
</template>

<style scoped lang="scss">
.error {
  position: absolute;
  top: 0;
  margin-top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ff003b28;
  color: #ff8a88;
  padding: 10px;
  font-size: 12px;
  row-gap: 8px;
  column-gap: 12px;
  border-radius: 8px;
  transition: opacity 5s;
  opacity: 1;
  img {
    margin-right: 6px;
    height: 10px;
    width: 10px;
    margin-top: -2px;
  }
}

.fade {
  opacity: 0;
}
</style>