import { ref } from "@vue/reactivity"
import type { SolutioningItem } from "@Heirloom/common"
import { sock, BIND } from "@app/state/common"
import { solicitation } from "@app/state/solicitation"
import {
  Document,
  Paragraph,
  TextRun,
  Packer,
} from 'docx';
import { saveAs } from 'file-saver';

//? Load
export const solutioning = ref<SolutioningItem[]>([])
export const loadSolutioning = (id: string) => {
  if (id !== solicitation.value?.id) solutioning.value = []
  sock.pub('solutioning/load', { id })
}
BIND('solutioning:load', () => s => solutioning.value = s.sort((a, b) => b.created.localeCompare(a.created)))

//? Update
export const updateSolutioning = (itemId: string, question: string, answer: string) =>
  sock.pub('solutioning/update', { id: solicitation.value!.id, itemId, question, answer })

//? Add
export const addSolutioning = () =>
  sock.pub('solutioning/create', { id: solicitation.value!.id })

//? Delete
export const deleteSolutioning = (itemId: string) =>
  sock.pub('solutioning/delete', { id: solicitation.value!.id, itemId })

//? Auto-Answer
export const answerSolutioning = () =>
  sock.pub('solutioning/answer', { id: solicitation.value!.id })

//? Download
export const downloadSolutioning = async (): Promise<void> => {
  const doc = new Document({
    sections: [
      {
        properties: {},
        children: solutioning.value.map((item) =>
          new Paragraph({
            children: [
              new TextRun({ text: `Question: ${item.question}`, bold: true, break: 1 }),
              new TextRun({ text: `Answer: ${item.answer}`, break: 1 }),
              new TextRun({ text: "", break: 1 })
            ]
          })
        )
      }
    ]
  });
  const blob = await Packer.toBlob(doc);
  saveAs(blob, `${(solicitation.value?.title ?? 'solutioning').replace(/[^\w\s]/gi, '')}_Solutioning.docx`);
};

//? Delta
BIND('△:solutioning', () => s => s.id === solicitation.value?.id ? loadSolutioning(s.id) : null)