<script setup lang="ts">
import { snowing } from '@app/state/view';
import { Group } from '@app/state/commands';
import { onUnmounted } from 'vue';

const snowflakeCount = 200

Group("Fun", onUnmounted)
  .command({
    icon: "lucide:snowflake",
    label: "Toggle Snow",
    perform: () => (snowing.value = !snowing.value),
  })
  .compile()
</script>

<template>
  <div class="snow-container" v-if="snowing">
    <div
      v-for="n in snowflakeCount"
      :key="n"
      class="snow"
      :style="{
        '--size': `${Math.random() * 12 + 8}px`,
        '--left': `${Math.random() * 100}vw`,
        '--delay': `-${Math.random() * 10}s`,
      }"
    >
      ❄️
    </div>
  </div>
</template>

<style lang="scss" scoped>
.snow-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: 100;
  overflow: hidden;
}

.snow {
  position: absolute;
  font-size: var(--size);
  left: var(--left);
  animation: snowfall 10s linear var(--delay) infinite;
  will-change: transform;
}

@keyframes snowfall {
  0% {
    transform: translateY(-10vh) rotate(0deg);
  }
  100% {
    transform: translateY(110vh) rotate(360deg);
  }
}
</style>
