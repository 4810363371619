<script setup async lang="ts">
import { listTenants, tenants, escalate } from '@app/state/tenant';
import Card from '@app/components/common/Card.vue';
import Badge from "@app/components/common/Badge.vue";

escalate()
listTenants()
</script>

<template>
  <div class="tenants">
    <Card v-for="tenant in tenants" :key="tenant.id" class="tenant">
      <div class="name">{{ tenant.name }}</div>
      <div class="slug">
        /{{ tenant.slug }}
        <Badge color="#5b83ff20">{{ tenant.users_with_access.length }} users</Badge>
      </div>
    </Card>
  </div>
</template>

<style scoped lang="scss">
.tenants {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin-top: 20px;
}

.tenant {
  width: 400px;
}

.name {
  font-size: 16px;
  font-weight: 600;
  color: var(--strong-font-color);
}

.slug {
  font-size: 12px;
  font-weight: 400;
  color: var(--primary-font-color);
}
</style>