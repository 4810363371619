<script async setup lang="ts">
import type { OpportunityMeta } from "@Heirloom/common"
import {
  deleteSolicitation,
  downloadSolicitation,
  listSolicitations,
  solicitations,
} from "@app/state/solicitation"
import { App, app, tab, tabs } from "@app/state/view"
import SolicitationCard from "@app/components/write/SolicitationCard.vue"
import Grid from "@app/components/ui/flex/Grid.vue"
import { slug } from "@app/state/common"
import router from "@app/router"
import {
  ContextMenu,
  ContextMenuTrigger,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuShortcut,
} from "@app/components/ui/context-menu"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogClose,
  DialogTrigger,
} from "@app/components/ui/dialog"
import { Icon } from "@iconify/vue"
import Button from "@app/components/ui/button/Button.vue"

listSolicitations()

const openSolicitation = (solicitation: OpportunityMeta) => {
  const exists = tabs.value.find(
    (tab) => tab.type === "solicitation" && tab.id === solicitation.id
  )
  if (!exists)
    tabs.value.push({
      name: solicitation.name.replace(".pdf", ""),
      type: "solicitation",
      id: solicitation.id,
      solicitation,
    })
  router.push(`/t/${slug}/write/${solicitation.id}`)
}
</script>

<template>
  <Grid
    item-width="220px"
    gap="35"
    margin="20px 0"
    v-if="solicitations.length > 0"
  >
    <ContextMenu
      v-for="solicitation in solicitations"
      :key="solicitation.id"
      as-child
    >
      <ContextMenuTrigger>
        <SolicitationCard
          @click="openSolicitation(solicitation)"
          :solicitation="solicitation"
        />
      </ContextMenuTrigger>
      <ContextMenuContent>
        <ContextMenuItem @click="downloadSolicitation(solicitation.id)">
          <span>Download</span>
          <ContextMenuShortcut>
            <Icon icon="lucide:download" />
          </ContextMenuShortcut>
        </ContextMenuItem>
        <Dialog>
          <DialogTrigger as-child>
            <ContextMenuItem @select.prevent>
              <span class="text-red-500">Delete</span>
              <ContextMenuShortcut>
                <Icon icon="lucide:trash" class="text-red-500" />
              </ContextMenuShortcut>
            </ContextMenuItem>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Delete Solicitation</DialogTitle>
            </DialogHeader>
            <DialogDescription>
              Any progress towards a draft will be lost. Are you sure that you
              want to proceed?
              <br /><br />
              <DialogClose>
                <Button
                  @click="deleteSolicitation(solicitation.id)"
                  variant="destructive"
                >
                  <template #icon>
                    <Icon icon="lucide:trash" />
                  </template>
                  Delete
                </Button>
              </DialogClose>
            </DialogDescription>
          </DialogContent>
        </Dialog>
      </ContextMenuContent>
    </ContextMenu>
  </Grid>
</template>

<style scoped lang="scss"></style>
