<script setup lang="ts">
import { useForwardPropsEmits } from 'radix-vue'
import type { DialogRootEmits, DialogRootProps } from 'radix-vue'
import Command from './Command.vue'
import { Dialog, DialogContent } from '@app/components/ui/dialog'

const props = defineProps<DialogRootProps>()
const emits = defineEmits<DialogRootEmits>()

const forwarded = useForwardPropsEmits(props, emits)
</script>

<template>
  <Dialog v-bind="forwarded">
    <DialogContent class="cmd-dialog-content">
      <Command class="command-component">
        <slot />
      </Command>
    </DialogContent>
  </Dialog>
</template>

<style>
.cmd-dialog-content {
  overflow: hidden;
  padding: 0;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  width: 90% !important;
  max-height: 50% !important;
}

.command-component {
  --group-heading-padding: 0.5rem;
  --group-heading-font-weight: 500;
  --group-heading-color: hsl(var(--muted-foreground));
  --group-padding: 0.5rem;
  --input-wrapper-icon-size: 1.25rem;
  --input-height: 3rem;
  --item-padding-x: 0.5rem;
  --item-padding-y: 0.75rem;
  --item-icon-size: 1.25rem;
}

.command-component [cmdk-group-heading] {
  padding-left: var(--group-heading-padding);
  padding-right: var(--group-heading-padding);
  font-weight: var(--group-heading-font-weight);
  color: var(--group-heading-color);
}

.command-component [cmdk-group]:not([hidden]) ~ [cmdk-group] {
  padding-top: 0;
}

.command-component [cmdk-group] {
  padding-left: var(--group-padding);
  padding-right: var(--group-padding);
}

.command-component [cmdk-input-wrapper] svg {
  height: var(--input-wrapper-icon-size);
  width: var(--input-wrapper-icon-size);
}

.command-component [cmdk-input] {
  height: var(--input-height);
}

.command-component [cmdk-item] {
  padding-left: var(--item-padding-x);
  padding-right: var(--item-padding-x);
  padding-top: var(--item-padding-y);
  padding-bottom: var(--item-padding-y);
}

.command-component [cmdk-item] svg {
  height: var(--item-icon-size);
  width: var(--item-icon-size);
}
</style>