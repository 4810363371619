<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import { cn } from '@app/utils/tailwind'

const props = defineProps<{
  class?: HTMLAttributes['class']
}>()
</script>

<template>
  <caption :class="cn('mt-4 text-sm text-muted-foreground', props.class)">
    <slot />
  </caption>
</template>
