<script setup lang="ts">
import type { HTMLAttributes } from 'vue'

const props = defineProps<{
  class?: HTMLAttributes['class']
}>()
</script>

<template>
  <div :class="['card-content', props.class]">
    <slot />
  </div>
</template>

<style scoped>
.card-content {
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  text-align: center;
}

@media (min-width: 640px) {
  .card-content {
    text-align: left;
  }
}
</style>