<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import { Icon } from '@iconify/vue'
import Paragraph from '@app/components/typography/Paragraph.vue';
import Muted from '@app/components/typography/Muted.vue';
import Card from '@app/components/ui/card/Card.vue'
import Columns from '@app/components/ui/flex/Columns.vue';
import Rows from '@app/components/ui/flex/Rows.vue';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@app/components/ui/collapsible"
import Markdown from '@app/components/common/Markdown.vue';
import type { LibraryDocument, LibraryDocumentMeta } from '@Heirloom/common';
import { computed, ref } from '@vue/reactivity';
import { app, tab, tabs, App } from '@app/state/view';
import { useRouter } from 'vue-router';
import { slug } from '@app/state/common';

const props = defineProps<{
  section: LibraryDocument['sections'][number] & {
    score?: string | number
    reference?: LibraryDocumentMeta
  }
}>()

const score = computed(() => (100 * parseFloat('' + props.section.score)).toFixed(2))
const expanded = ref(false)
const router = useRouter()

const openReference = () => {
  const reference = props.section.reference;
  if (!(reference)) return console.error('No reference provided.');

  const exists = tabs.value.find(tab => tab.type === 'reference' && tab.id === reference.id)
  if (!exists) tabs.value.push(
    {
      name: reference.name.replace('.pdf', ''),
      type: 'reference',
      id: reference.id,
      reference
    }
  )
  router.push(`/t/${slug}/library/${reference.id}`)
}
</script>

<template>
  <Card>
    <Collapsible class="reference" v-model:open="expanded">
      <CollapsibleTrigger>
        <Columns spread>
          <Rows class="section" flex="1" center>
            <span style="text-align: left">
              {{ section.title }}
            </span>
            <Muted style="text-align: left" v-if="section.reference">
              {{ score }}% match - section #{{ section.sectionNumber }} in {{ section.reference.name.replace('.pdf', '') }}
            </Muted>
          </Rows>

          <Columns width="40px" margin="10px" v-if="!expanded">
            <Button variant="secondary" size="icon">
              <Icon icon="lucide:chevrons-up-down" />
            </Button>
          </Columns>
          <Columns width="185px" margin="10px" gap="10" v-else>
            <Button variant="outline" @click.stop.prevent="openReference">
              Open Reference
            </Button>
            <Button variant="secondary" size="icon">
              <Icon icon="lucide:chevrons-down-up" />
            </Button>
          </Columns>
        </Columns>
      </CollapsibleTrigger>
      <CollapsibleContent>
        <Markdown :markdown="section.markdown ?? ''" />
      </CollapsibleContent>
    </Collapsible>
  </Card>
</template>

<style scoped lang="scss">
.reference {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  cursor: pointer;

  .section > p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>