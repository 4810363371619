<script async setup lang="ts">
import GoogleLogin from "@app/components/auth/GoogleLogin.vue"
import MicrosoftLogin from "@app/components/auth/MicrosoftLogin.vue"
import MagicLink from "@app/components/auth/MagicLink.vue"
import OIDCLogin from "@app/components/auth/OIDCLogin.vue"
import { Button } from "@app/components/ui/button"
import { Icon } from "@iconify/vue"
import { isBound, TOKEN, bind } from "@app/state/auth"
import { API, colorMode, version, slug } from "@app/state/common"
import { computed, ref } from "@vue/reactivity"

const mode = computed(() => colorMode.state.value)

const quotes = [
  {
    quote:
      "It’s like an assistant who knows our company – I just send it an RFP, and it sends me back a draft proposal.",
    author: "COO, Healthcare IT Contractor (SDVOSB)",
  },
  {
    quote:
      "I was simply stunned. It has dramatically reduced our time to pink draft.",
    author: "Capture Manager, Cybersecurity Firm",
  },
  {
    quote:
      "I have been doing this for 15 years and tried every software out there. I’ve never seen anything like ProposalAI.",
    author: "Proposal Writer, Professional Services Company",
  },
]

const OTP = window.location.search.match(/otp=([^&]+)/)?.[1]
const hasOTP = computed(() => !!OTP)
const failure = ref(false)
const loadingOTP = ref(false)
const verifyOTP = async () => {
  if (!OTP) return console.error("NO OTP FOUND")
  loadingOTP.value = true
  console.log("OTP found, attempting verification...")
  try {
    const { token, slug } = await API("/auth/otp", { token: OTP!, version })
    if (!token) throw new Error("Failed to get token.")
    localStorage.setItem(`HL:${slug}:token`, token)
    window.location.href = `/t/${slug}`
  } catch (e) {
    console.error(e)
    failure.value = true
  } finally {
    loadingOTP.value = false
  }
}

const code = window.location.search.match(/code=([^&]+)/)?.[1]
if (code) {
  console.log("OIDC code found, exchanging for token...")
  try {
    const verifier = sessionStorage.getItem("oidc_verifier")
    if (!verifier) throw new Error("No verifier found")

    const { token } = await API("/auth/oidc", {
      currentUrl: window.location.href,
      verifier,
      slug: slug!,
      version,
    })
    TOKEN.value = token
    await bind()
    sessionStorage.removeItem("oidc_verifier")
  } catch (e) {
    console.error(e)
    failure.value = true
  }
}

const quote = computed(() => quotes[Math.floor(Math.random() * quotes.length)])
</script>

<template>
  <div class="container" v-if="!isBound">
    <div class="left-panel">
      <div class="bg-overlay"></div>
      <div class="logo">
        <img
          :class="mode === 'dark' ? 'dark' : 'light'"
          src="@app/assets/img/logo-color.png"
        />
      </div>
      <div class="quote">
        <blockquote v-if="quote">
          <p>"{{ quote.quote }}"</p>
          <footer>{{ quote.author }}</footer>
        </blockquote>
      </div>
    </div>
    <div class="right-panel">
      <div class="form-container">
        <div class="form-header">
          <h1>Welcome to ProposalAI</h1>
        </div>
        <div class="auth-form-container" v-if="!hasOTP">
          <GoogleLogin class="auth-btn" />
          <MicrosoftLogin class="auth-btn" />
          <OIDCLogin class="auth-btn" />
          <MagicLink class="auth-btn" />
        </div>
        <div class="auth-form-container" v-if="hasOTP">
          <template v-if="!loadingOTP">
            <Button variant="outline" type="button" @click="verifyOTP">
              <Icon icon="lucide:sparkles" class="brand-icon" />
              Magic Sign-in
            </Button>
          </template>
          <template v-else>
            <p v-if="!failure">Please wait while we redirect you...</p>
            <p v-if="failure">
              Sorry, your magic link has expired or is invalid.
            </p>
          </template>
        </div>
        <p class="terms">
          By signing in to ProposalAI, you agree to our<br />
          <a href="https://govpro.ai/terms" class="terms-link"
            >Terms of Service</a
          >
          and
          <a href="https://govpro.ai/privacy" class="terms-link"
            >Privacy Policy</a
          >.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  max-width: unset;
}

.login-button {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.left-panel {
  position: relative;
  display: none;
  height: 100%;
  flex-direction: column;
  background-color: hsl(var(--popover-foreground));
  padding: 2.5rem;
  color: hsl(var(--primary-foreground));
}

.bg-overlay {
  position: absolute;
  inset: 0;
  background-color: transparent;
}

.logo {
  position: relative;
  z-index: 20;
  display: flex;
  align-items: center;
  

  img {
    width: 200px;

    &.light {
      filter: brightness(100);
    }
    &.dark {
      filter: brightness(0);
    }
  }
}

.quote {
  position: relative;
  z-index: 20;
  margin-top: auto;
}

.quote p {
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
}

.quote footer {
  font-size: var(--text-sm);
}

.right-panel {
  max-width: 320px;
  margin: auto;
}

.form-container {
  margin: 0 auto;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
}

.auth-form-container {
  display: grid;
  gap: 1.5rem;
}

.form-header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
}

.form-header h1 {
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: -0.025em;
}

.form-header p {
  font-size: var(--text-sm);
  color: hsl(var(--muted-foreground));
  /* text-muted-foreground */
}

.terms {
  padding: 0 1rem;
  text-align: center;
  font-size: var(--text-sm);
  color: hsl(var(--muted-foreground));
  /* text-muted-foreground */
}

.terms-link {
  text-decoration: underline;
  text-underline-offset: 4px;
}

.terms-link:hover {
  color: hsl(var(--foreground));
  /* text-primary */
}

@media (min-width: 768px) {
  .container {
    display: grid;
  }

  .login-button {
    right: 2rem;
    top: 2rem;
  }
}

@media (min-width: 1024px) {
  .container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 0;
  }

  .left-panel {
    display: flex;
    border-right: 1px solid hsl(var(--border));
    /* dark:border-r */
  }

  .form-container {
    max-width: 350px;
  }
}

.brand-icon {
  margin-right: 0.5rem;
  height: 1rem;
  width: 1rem;
}
</style>
