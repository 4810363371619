<template>
  <div :class="['card-header', props.class]">
    <slot />
  </div>
</template>

<script setup lang="ts">
import type { HTMLAttributes } from 'vue'

const props = defineProps<{
  class?: HTMLAttributes['class']
}>()
</script>

<style scoped>
.card-header {
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  padding: 1.5rem;
}

.dashboard-card {
  background-color: hsl(var(--background));
  border-radius: 0.5rem;
  box-shadow: 0 0px 45px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.card-title {
  font-size: var(--text-sm);
  font-weight: 500;
}

.card-icon {
  height: 1rem;
  width: 1rem;
  color: hsl(var(--muted-foreground));
}

.card-value {
  font-size: 1.5rem;
  font-weight: bold;
}

.card-change {
  font-size: var(--text-xs);
  color: hsl(var(--muted-foreground));
}
</style>