import { createWebHistory, createRouter, type RouteRecordRaw } from "vue-router"
import Anchor from "@app/views/Anchor.vue"
import Admin from "@app/views/Admin.vue"
import Bid from "@app/views/Bid.vue"
import { app, tab, tabs, App } from "@app/state/view"
import { nextTick } from "vue"
import { listSolicitations, solicitations } from "@app/state/solicitation"
import { references } from "@app/state/reference"
import { listReferences } from "@app/state/reference"
import { sleep } from "@app/state/common"

const routes: RouteRecordRaw[] = [
	{
		path: "/",
		name: "ProposalAI Intranet",
		component: Anchor,
	},
	{
		path: "/tomato",
		name: "ProposalAI | 🍅",
		component: Admin,
	},
	{
		path: "/t/:tenant",
		name: "ProposalAI",
		component: Bid,
	},
	{
		path: '/magic',
		name: 'Signing in...',
		component: Bid,
	},
	{
		path: '/t/:tenant/write',
		name: 'ProposalAI | Write',
		component: Bid,
	},
	{
		path: '/t/:tenant/library',
		name: 'ProposalAI | Library',
		component: Bid,
	},
	{
		path: '/t/:tenant/chat',
		name: 'ProposalAI | Chat',
		component: Bid,
	},
	{
		path: '/t/:tenant/feed',
		name: 'ProposalAI | Feed',
		component: Bid,
	},
	{
		path: '/t/:tenant/write/:id',
		name: 'ProposalAI | Opportunity',
		component: Bid,
	},
	{
		path: '/t/:tenant/library/:id',
		name: 'ProposalAI | Reference',
		component: Bid,
	},
	{
		path: '/t/:tenant/chat/:id',
		name: 'ProposalAI | Conversation',
		component: Bid,
	},
	{
		path: "/:pathMatch(.*)*",
		name: "Are you lost?",
		component: Anchor,
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		return { top: 0 }
	}
})
export default router

router.beforeEach(async (to, from, next) => {
  if (to.name?.toString().startsWith('ProposalAI')) {
    next()
    const id = to.params.id;
    switch (to.name) {
      case 'ProposalAI':
        app.value = App.NONE
				document.title = `ProposalAI`
        break;
      case 'ProposalAI | Write':
        app.value = App.WRITE
				document.title = `ProposalAI | Write`
        break;
      case 'ProposalAI | Library':
        app.value = App.LIBRARY
				document.title = `ProposalAI | Library`
        break;
      case 'ProposalAI | Chat':
        app.value = App.CHAT
				document.title = `ProposalAI | Chat`
        break;
      case 'ProposalAI | Opportunity':
      case 'ProposalAI | Reference':
      case 'ProposalAI | Conversation':
				if (!(from.name?.toString().startsWith('ProposalAI'))) await sleep(1000)
        let t = tabs.value.find(t => t.id === id)
				if (!t) {
					if (to.name.startsWith('ProposalAI | Opportunity')) {
						if (solicitations.value.length === 0) {
							listSolicitations()
							await sleep(500)
						}
						const solicitation = solicitations.value.find(s => s.id === id)
						if (solicitation) {
							t = {
								name: solicitation.name.replace(".pdf", ""),
								type: "solicitation",
								id: solicitation.id,
								solicitation,
							}
							tabs.value.push(t)
						} else console.warn(`Solicitation ${id} not found`)
					} else if (to.name.startsWith('ProposalAI | Reference')) {
						if (references.value.length === 0) {
							listReferences()
							await sleep(500)
						}
						const reference = references.value.find(r => r.id === id)
						if (reference) {
							t = {
								name: reference.name.replace(".pdf", ""),
								type: "reference",
								id: reference.id,
								reference,
							}
							tabs.value.push(t)
						} else console.warn(`Reference ${id} not found`)
					}
				}
        if (!t) break;
        app.value = App.TAB
        tab.value = null
        nextTick(() => {
          tab.value = t
        })
        document.title = `ProposalAI | ${t.name || to.name || 'Dashboard'}`
        break;
      default:
        app.value = App.NONE
				document.title = `ProposalAI`
        break;
    }
  } else next()
})

