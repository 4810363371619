<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import {
  DropdownMenuSubTrigger,
  type DropdownMenuSubTriggerProps,
  useForwardProps,
} from 'radix-vue'
import { ChevronRightIcon } from '@radix-icons/vue'

const props = defineProps<DropdownMenuSubTriggerProps & { class?: HTMLAttributes['class'] }>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props
  return delegated
})

const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <DropdownMenuSubTrigger
    v-bind="forwardedProps"
    :class="['dropdown-menu-sub-trigger', props.class]"
  >
    <slot />
    <ChevronRightIcon class="chevron-icon" />
  </DropdownMenuSubTrigger>
</template>

<style scoped>
.dropdown-menu-sub-trigger {
  display: flex;
  cursor: default;
  user-select: none;
  align-items: center;
  border-radius: 0.125rem;
  padding: 0.375rem 0.5rem;
  font-size: var(--text-sm);
  outline: none;
}

.dropdown-menu-sub-trigger:focus {
  background-color: hsl(var(--muted));
}

.dropdown-menu-sub-trigger[data-state="open"] {
  background-color: hsl(var(--muted));
}

.chevron-icon {
  margin-left: auto;
  height: 1rem;
  width: 1rem;
}
</style>