<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import { DropdownMenuLabel, type DropdownMenuLabelProps, useForwardProps } from 'radix-vue'

const props = defineProps<DropdownMenuLabelProps & { class?: HTMLAttributes['class'], inset?: boolean }>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props
  return delegated
})

const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <DropdownMenuLabel
    v-bind="forwardedProps"
    :class="['dropdown-menu-label', inset ? 'inset' : '', props.class]"
  >
    <slot />
  </DropdownMenuLabel>
</template>

<style scoped>
.dropdown-menu-label {
  padding: 0.375rem 0.5rem;
  font-size: var(--text-sm);
  font-weight: 600;
  color: hsl(var(--foreground));
}

.dropdown-menu-label.inset {
  padding-left: 2rem;
}

</style>