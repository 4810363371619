<script async setup lang="ts">
import MDSection from '@app/components/response/MDSection.vue'
import type { ResponseSectionTree } from '@app/state/response';

defineProps<{
  sections: ResponseSectionTree[]
}>()
</script>

<template>
  <MDSection v-for="section in sections" :section="section" />
</template>

