<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import { cn } from '@app/utils/tailwind'

const props = defineProps<{
  class?: HTMLAttributes['class']
}>()
</script>

<template>
  <h5 :class="['card-title', props.class]">
    <slot />
  </h5>
</template>

<style scoped>
.card-title {
  margin-bottom: 0.25rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.025em;
}
</style>