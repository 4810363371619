<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import { Icon } from '@iconify/vue'
import { ComboboxInput, type ComboboxInputProps, useForwardProps } from 'radix-vue'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps<ComboboxInputProps & {
  class?: HTMLAttributes['class']
}>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <div class="search-wrapper" cmdk-input-wrapper>
    <Icon icon="radix-icons:magnifying-glass" class="search-icon" />
    <ComboboxInput
      v-bind="{ ...forwardedProps, ...$attrs }"
      auto-focus
      :class="['search-input', props.class]"
    />
  </div>
</template>

<style scoped>
.search-wrapper {
  display: flex;
align-items: center;
border-bottom: 1px solid hsl(var(--input));
padding: 0 0 0 0.75rem;
margin: 0 -0.375rem 0 0;
width: 100%;
}

.search-icon {
  margin-right: 0.5rem;
  height: 1rem;
  width: 1rem;
  flex-shrink: 0;
  opacity: 0.5;
}

.search-input {
  display: flex;
  height: 2.5rem;
  width: calc(100% - 2rem);
  border-radius: var(--radius);
  background-color: transparent;
  padding: 0.75rem 0;
  font-size: var(--text-sm);
  outline: none;
}

.search-input::placeholder {
  color: hsl(var(--muted-foreground));
}

.search-input:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>