<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import {
  ContextMenuSubContent,
  type DropdownMenuSubContentEmits,
  type DropdownMenuSubContentProps,
  useForwardPropsEmits,
} from 'radix-vue'

const props = defineProps<DropdownMenuSubContentProps & { class?: HTMLAttributes['class'] }>()
const emits = defineEmits<DropdownMenuSubContentEmits>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props
  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <ContextMenuSubContent
    v-bind="forwarded"
    :class="['context-menu-sub-content', props.class]"
  >
    <slot />
  </ContextMenuSubContent>
</template>

<style scoped>
:deep(.context-menu-sub-content) {
  z-index: 50;
  min-width: 8rem;
  overflow: hidden;
  border-radius: var(--radius);
  border: 1px solid hsl(var(--border));
  background-color: hsl(var(--popover));
  color: hsl(var(--popover-foreground));
  padding: 0.25rem;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

:deep(.context-menu-sub-content[data-state="open"]) {
  animation: fadeIn 150ms ease-out, zoomIn 150ms ease-out;
}

:deep(.context-menu-sub-content[data-state="closed"]) {
  animation: fadeOut 150ms ease-in, zoomOut 150ms ease-in;
}

:deep(.context-menu-sub-content[data-side="bottom"]) {
  animation: slideFromTop 150ms ease-out;
}

:deep(.context-menu-sub-content[data-side="left"]) {
  animation: slideFromRight 150ms ease-out;
}

:deep(.context-menu-sub-content[data-side="right"]) {
  animation: slideFromLeft 150ms ease-out;
}

:deep(.context-menu-sub-content[data-side="top"]) {
  animation: slideFromBottom 150ms ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes zoomIn {
  from { transform: scale(0.95); }
  to { transform: scale(1); }
}

@keyframes zoomOut {
  from { transform: scale(1); }
  to { transform: scale(0.95); }
}

@keyframes slideFromTop {
  from { transform: translateY(-0.5rem); }
  to { transform: translateY(0); }
}

@keyframes slideFromRight {
  from { transform: translateX(0.5rem); }
  to { transform: translateX(0); }
}

@keyframes slideFromLeft {
  from { transform: translateX(-0.5rem); }
  to { transform: translateX(0); }
}

@keyframes slideFromBottom {
  from { transform: translateY(0.5rem); }
  to { transform: translateY(0); }
}
</style>