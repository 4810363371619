<script lang="ts" setup>
import { Progress } from '@app/components/ui/progress'
import { computed } from '@vue/reactivity'
import { Icon } from '@iconify/vue'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@app/components/ui/tooltip'
import Columns from '@app/components/ui/flex/Columns.vue'
import Rows from '@app/components/ui/flex/Rows.vue'
import Paragraph from '@app/components/typography/Paragraph.vue'
import { Button } from '@app/components/ui/button'
import { listSolicitations, solicitations } from '@app/state/solicitation'
import { Tenant } from '@app/state/auth'

listSolicitations()
const used = computed(() => solicitations.value.length)
const max = computed(() => solicitations.value.length + (Tenant.value?.credits ?? 0))
const progress = computed(() => used.value / max.value * 100)
</script>

<template>
  <Rows v-if="Tenant?.credits != null" padding="0 12px">
    <Tooltip :delay-duration="0">
      <TooltipTrigger as-child>
        <Paragraph>{{ used }} / {{ max }} credits</Paragraph>
        <Progress v-model="progress" />
      </TooltipTrigger>
      <TooltipContent side="right">
        Credits used out of total quota (contact support for issues)
      </TooltipContent>
    </Tooltip>
    <Rows padding="12px 0">
      <a href="mailto:sales@govpro.ai?subject=Credit Increase Request">
        <Button variant="outline">
          Get more credits
        </Button>
      </a>
    </Rows>
  </Rows>
</template>