<script async setup lang="ts">
import { ref } from "@vue/reactivity"
import { createSolicitation } from "@app/state/solicitation"
import { computed } from "vue"
import Button from "@app/components/ui/button/Button.vue"
import { Icon } from "@iconify/vue"
import Paragraph from "@app/components/typography/Paragraph.vue"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogClose,
  DialogTitle,
  DialogTrigger,
} from "@app/components/ui/dialog"
import { Tenant } from "@app/state/auth"

const fileInput = ref<HTMLInputElement>()
const files = ref<File[]>([])
const handleFileUpload = (event: Event) => {
  const target = event.target as HTMLInputElement
  files.value = Array.from(target.files ?? [])
}
const invalid = computed(() =>
  files.value.filter((file) => file.size > 200 * 1024 * 1024)
)

const create = () => {
  files.value.map(createSolicitation)
  resetUploadFlow()
}

const resetUploadFlow = () => {
  files.value = []
  if (fileInput.value) {
    fileInput.value.value = ""
  }
}
</script>

<template>
  <Dialog>
    <DialogTrigger>
      <Button variant="default" @click="resetUploadFlow">
        <template #icon>
          <Icon icon="lucide:plus" />
        </template>
        Add Solicitation
      </Button>
    </DialogTrigger>
    <DialogContent v-if="(Tenant?.credits ?? 99999) > 0">
      <DialogHeader>
        <DialogTitle v-if="files.length <= 0">Upload New Solicitation</DialogTitle>
        <DialogTitle v-if="files.length > 0">Confirm Upload</DialogTitle>
      </DialogHeader>
      <DialogDescription>
        <Paragraph v-if="files.length <= 0"
          >ProposalAI works best with <b>PDFs</b>, but supports Word, Powerpoint,
          Excel, images, audio, and text.</Paragraph
        >

        <input
          ref="fileInput"
          multiple
          type="file"
          style="display: none"
          @change="handleFileUpload"
        />

        <div v-if="files.length > 0">
          You have selected <b>{{ files.length }}</b> file(s), and
          <b
            >{{
              (
                files.reduce((acc, file) => acc + file.size, 0) /
                1024 /
                1024
              ).toFixed(2)
            }}
            MB</b
          >
          will be uploaded.
        </div>
        <div v-if="invalid.length > 0">
          <br />
          However, <b>{{ invalid.length }}</b> of them will be ignored as they
          are <b>too large</b>, please stay under <b>200MB</b>.
        </div>
        <br />
        <Button
          variant="default"
          @click="fileInput!.click()"
          v-if="files.length <= 0"
        >
          <template #icon>
            <Icon icon="lucide:upload" />
          </template>
          Upload Files
        </Button>

        <DialogClose v-if="files.length > 0 && files.length > invalid.length">
          <Button variant="default" @click="create">
            <template #icon>
              <Icon icon="lucide:check" />
            </template>
            Add Solicitation
          </Button>
        </DialogClose>
      </DialogDescription>
    </DialogContent>
    <DialogContent v-else>
      <DialogHeader>
        <DialogTitle>You're out of credits.</DialogTitle>
      </DialogHeader>
      <DialogDescription>
        <Paragraph>
          <b>It looks like you've used up all of your credits.</b> To buy more, contact your account manager.
        </Paragraph>
        <Paragraph>
          <i>If you think this may be an error, please <a class="text-indigo-500" href="mailto:support@govpro.ai">Contact Support</a>.</i>
        </Paragraph>

        <br />
        <a href="mailto:sales@govpro.ai">
          <Button variant="default">
            <template #icon>
              <Icon icon="lucide:ticket-plus" />
            </template>
            Buy more credits
          </Button>
        </a>
      </DialogDescription>
    </DialogContent>
  </Dialog>
</template>

<style lang="scss" scoped>
b {
  color: hsl(var(--foreground)) !important;
}
</style>
