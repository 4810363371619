<script setup lang="ts">
import type { HTMLAttributes } from 'vue'

const props = defineProps<{
  class?: HTMLAttributes['class']
}>()
</script>

<template>
  <div :class="['card-content', props.class]">
    <slot />
  </div>
</template>

<style scoped>
.card-content {
  padding: 1.5rem;
  padding-top: 0;
}


</style>