<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import {
  DropdownMenuSeparator,
  type DropdownMenuSeparatorProps,
} from 'radix-vue'

const props = defineProps<DropdownMenuSeparatorProps & {
  class?: HTMLAttributes['class']
}>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props
  return delegated
})
</script>

<template>
  <DropdownMenuSeparator v-bind="delegatedProps" :class="['dropdown-menu-separator', props.class]" />
</template>

<style scoped>
.dropdown-menu-separator {
  margin: 0.25rem -0.25rem;
  height: 1px;
  background-color: hsl(var(--muted));
}
</style>