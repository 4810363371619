//! Minimize non-type imports

//////////////////////////////////////////////////////////////////////////*
//* Exposed API
export type Version = { version: string }
export type Endpoints = {
    "/auth/google": {
        Q: { accessToken: string, slug: string } & Version,
        S: { token: string }
    },
    "/auth/microsoft": {
        Q: { idToken: string, slug: string } & Version,
        S: { token: string }
    },
    "/auth/magic-link": {
        Q: { email: string, slug: string } & Version,
        S: { success: boolean, token?: string }
    },
    "/auth/otp": {
        Q: { token: string } & Version,
        S: { slug: string, token: string }
    },
    "/auth/oidc": {
        Q: { currentUrl: string, verifier: string, slug: string } & Version,
        S: { token: string }
    },
    "/auth/oidc/redirect": {
        Q: { slug: string } & Version,
        S: { url: string, verifier: string }
    }
}

export type Messages = {
    //** Socket */
    'error': { error: string },
    "bind": { token: string, tenant: string },
    "bound": { id: string, username: string, staff: boolean },


    //** Scaling */
    '🧠/status': {},
    '🧠:status': { status: 'warm' | 'cold' | 'warming' | 'cooling' },
    '🧠/warm': {},
    '🧠/cool': {},


    //** Tenant */
    //? Create
    'tenant/create': { name: string, slug: string }, //! STAFF
    //? Read
    'tenant/list': {}, //! STAFF
    'tenant:list': StackTenant[], //! STAFF
    '△:tenant': StackTenant,
    ///* ///////////////////////////////////////////////////////

    //** Library */
    //? Create
    'library/create': { filename: string },
    'library:upload': { filename: string, url: string, id: string },
    //? Read
    'library/load': { id: string },
    'library:load': LibraryDocument,
    'library/list': {},
    'library:list': LibraryDocumentMeta[],
    '△:library': LibraryDocument,
    //? Update
    'library/parse': { id: string },
    //? Delete
    'library/delete': { id: string },
    //? Pages
    'library/page/load': { id: string, pageId: string },
    'library/page:load': { image: string, id: string },
    //? Sections
    'library/section/disable': { id: string, sectionId: string },
    //? Export
    'library/download': { id: string },
    'library:download': { url: string },
    //? Chat
    'library/chat/single': { id: string, message: string },
    'library/chat:single': { messagePart?: string, sections?: LibraryDocument['sections'] },
    'library/chat/all': { message: string, conversationId: string, history?: { message: string, type: 'query' | 'response' }[], summaries?: string[] },
    'library/chat:all': { conversationId: string, messagePart?: string, sections?: LibraryDocument['sections'], title?: string, summary?: string },
    ///* ///////////////////////////////////////////////////////


    //** Opportunity */
    //? Create
    'opportunity/create': { filename: string },
    'opportunity:upload': { filename: string, url: string, id: string },
    //? Read
    'opportunity/load': { id: string },
    'opportunity:load': Opportunity,
    'opportunity/list': {},
    'opportunity:list': OpportunityMeta[],
    '△:opportunity': Opportunity,
    //? Update
    'opportunity/parse': { id: string },
    'opportunity/shred': { id: string },
    //? Delete
    'opportunity/delete': { id: string },
    //? Pages
    'opportunity/page/load': { id: string, pageId: string },
    'opportunity/page:load': { image: string, id: string },
    //? Sections
    'opportunity/section/tag': { id: string, sectionId: string, tag: string },
    'opportunity/section/autotag': { id: string },
    //? Attachments
    'opportunity/attachment/create': { id: string, filename: string },
    'opportunity/attachment:upload': { filename: string, url: string, key: string },
    'opportunity/attachment/download': { id: string, key: string },
    'opportunity/attachment/parse': { id: string, key: string },
    //? Export
    'opportunity/download': { id: string },
    'opportunity:download': { url: string },
    //? Chat
    'opportunity/chat': { id: string, message: string },
    'opportunity:chat': { messagePart?: string, sections?: { id: string, title: string, markdown: string, tags: string[] }[] },
    ///* ///////////////////////////////////////////////////////


    //** Solutioning */
    //? Create
    'solutioning/create': { id: string },
    //? Read
    'solutioning/load': { id: string },
    'solutioning:load': SolutioningItem[],
    '△:solutioning': { id: string },
    //? Update
    'solutioning/update': { id: string, itemId: string, question: string, answer: string },
    'solutioning/answer': { id: string },
    //? Delete
    'solutioning/delete': { id: string, itemId: string },
    ///* ///////////////////////////////////////////////////////


    //** Compliance */
    //? Read
    'compliance/load': { id: string },
    'compliance:load': ComplianceItem[],
    '△:compliance': { id: string },
    //? Update
    'compliance/check': { id: string },
    ///* ///////////////////////////////////////////////////////


    //** Response */
    //? Read
    'response/load': { id: string },
    'response:load': ResponseSection[],
    '△:response': { id: string },
    //? Update
    'response/outline': { id: string, instructions: Maybe<string> },
    //? Sections
    'response/section/create': { id: string, prevId?: Maybe<string>, parentId?: Maybe<string> },
    'response/section/update': { id: string, sectionId: string, data: { title?: Maybe<string>, points?: Maybe<string>, order?: Maybe<number>, paragraphs?: Maybe<number> } },
    'response/section/references': { id: string, sectionId: string, references: Maybe<string[]> },
    'response/section/generate': { id: string, sectionId: string, instructions: Maybe<string> },
    'response/section/delete': { id: string, sectionId: string },
    //? Export
    'response/export': { id: string },
    'response:export': { url: string },
    ///* ///////////////////////////////////////////////////////
}

export interface SocketData {
    token: string
}


//////////////////////////////////////////////////////////////////////////*
//* Common Types

export interface StackTenant {
    id: string
    name: string
    slug: string
    credits: Maybe<number>
    users_with_access: string[]
}

export interface LibraryDocument {
    id: string
    created: string
    name: string
    status: "created" | "processing" | "parsed" | "indexed" | "deleted"

    title: Maybe<string>
    thumbnail: Maybe<string>

    pages: {
        id: string
        pageNumber: number
        markdown: Maybe<string>
        tags: string[]
    }[]

    sections: {
        id: string
        sectionNumber: number
        markdown: string
        title: string
        enabled: boolean
        referenceId: string
    }[]
}

export interface LibraryDocumentMeta {
    id: string
    created: string
    name: string
    status: "created" | "processing" | "parsed" | "indexed" | "deleted"
    title: Maybe<string>
    thumbnail: Maybe<string>
}

export interface Opportunity {
    id: string
    created: string
    name: string
    status: "created" |
    "processing" |
    "parsed" |
    "tagging" |
    "extraction" |
    "solutioning" |
    "generating" |
    "outlining" |
    "writing" |
    "checking" |
    "compliance" |
    "complete" |
    "deleted" |
    "error"

    title: Maybe<string>
    thumbnail: Maybe<string>
    markdown: Maybe<string>

    bluf: Maybe<string>
    structure: Maybe<string>

    shallStatements: string[]
    attachments: string[]

    meta: OpportunityMetadata[]
    pages: OpportunityPage[]
    sections: OpportunitySection[]
}

export interface OpportunityMeta {
    id: string
    created: string
    name: string
    status: "created" |
    "processing" |
    "parsed" |
    "tagging" |
    "extraction" |
    "solutioning" |
    "generating" |
    "outlining" |
    "writing" |
    "checking" |
    "compliance" |
    "complete" |
    "deleted" |
    "error"
    title: Maybe<string>
    thumbnail?: Maybe<string>
    bluf: Maybe<string>
}

export interface OpportunityMetadata {
    id: string
    key: string
    value: string
}

export interface OpportunityPage {
    id: string
    pageNumber: number
    markdown: Maybe<string>
    tags: string[]
}

export interface OpportunitySection {
    id: string
    markdown: string
    title: string
    tags: string[]
    score?: Maybe<string | number>
}

export interface SolutioningItem {
    id: string
    created: string
    question: string
    answer: Maybe<string>
}

export interface ComplianceItem {
    id: string
    text: string
    completed: boolean
    answer: Maybe<string>
    basis: string
    category: Maybe<string>
    sections: string[]
}

export interface ResponseSection {
    id: string
    order: number
    title: string
    points: string

    parentId: Maybe<string>
    references: {
        id: string
        sectionNumber: number
        markdown: string
        title: string
        enabled: boolean
        referenceId: string
    }[]
    paragraphs: ResponseParagraph[]
}

export interface ResponseParagraph {
    id: string
    paragraphNumber: number
    markdown: string
}

//////////////////////////////////////////////////////////////////////////*
//* Networking & SockPuppet
export type Maybe<T> = T | null | undefined
export type Maybeable<T> = { [P in keyof T]?: Maybeable<T[P]> }
export type Handler<Q, S> = (q: Q) => S | Promise<S>
export type Payload<S> = (S | {
    error: string
}) & {
    statusCode?: number
}
export type Endpoint = keyof Endpoints;
export type Request<E extends Endpoint> = Endpoints[E]["Q"]
export type Response<E extends Endpoint> = Endpoints[E]["S"]
export type Route<E extends Endpoint> = Handler<Request<E>, Payload<Response<E>>>

export type Event = keyof Messages;
export type Message<E extends Event> = Messages[E]
export type Channel<E extends Event> = ({ pub, sub }: PubSub) => (m: Message<E>) => void | Promise<void> | any | Promise<any>
export type Port<E extends Event> = Handler<Message<E>, void>
export interface Socketish {
    id: string
    on: (e: string, f: (d: any) => void | any | never | Promise<void> | Promise<any> | Promise<never>) => void
    emit: (e: string, v: any) => void
    join(rooms: string | string[]): Promise<void> | void
    disconnect(close?: boolean): any
    data: SocketData
}
export interface IOish {
    emit: (e: string, v: any) => void
    to(room: string): IOish
}
export const pubsub = (socket: Socketish, io: IOish) => ({
    socket,
    sub: <E extends Event>(e: E, f: Port<E>) =>
        // @ts-ignore
        socket.on(e, f),
    pub: <E extends Event>(e: E, v: Message<E>) => socket.emit(e, v),
    broadcast: <E extends Event>(e: E, v: Message<E>, room: string) => io.to(room).emit(e, v),
})
export type PubSub = ReturnType<typeof pubsub>
export const receiver = (ps: PubSub) => <E extends Event>(e: E, f: Channel<E>) => ps.sub(e, f(ps))
