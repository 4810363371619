<script async setup lang="ts">
import PerlinNoise from '@app/components/common/PerlinNoise.vue';
import { colorMode } from '@app/state/common';
import { computed } from '@vue/reactivity';

const mode = computed(() => colorMode.state.value);
</script>

<template>
    <div class="loader">
        <PerlinNoise class="middle" :class="mode" />
    </div>
</template>

<style lang="scss" scoped>
.loader {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 400px;
    filter: hue-rotate(50deg) grayscale(100%);
}

.light {
    filter: hue-rotate(50deg) grayscale(100%) invert(1);
}
</style>