<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import { Icon } from '@iconify/vue'

const props = defineProps<{ class?: HTMLAttributes['class'] }>()
</script>

<template>
  <div :class="['card-footer', props.class]">
    <slot />
  </div>
</template>

<style scoped>
.card-footer {
  display: flex;
  flex-direction: column-reverse;
}

@media (min-width: 640px) {
  .card-footer {
    flex-direction: row;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}
</style>