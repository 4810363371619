<script async setup lang="ts">
import { loadResponse, response } from '@app/state/response';
import type { ComplianceItem } from '@Heirloom/common';
import { computed } from '@vue/reactivity';
import { Badge } from '@app/components/ui/badge';
import { Button } from '@app/components/ui/button';
import { Icon } from '@iconify/vue';
import Paragraph from '@app/components/typography/Paragraph.vue'
import {
  TableCell,
  TableRow,
} from "@app/components/ui/table"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@app/components/ui/popover'
import { solicitation } from '@app/state/solicitation';
import Markdown from '@app/components/common/Markdown.vue';
const props = defineProps<{
  item: ComplianceItem
  order: number
}>()

const referencedIn = computed(() => solicitation.value!.sections.filter(s => s.markdown.includes(props.item.basis)))
const addressedIn = computed(() => response.value.filter(r => props.item.sections.includes(r.id)))

const highlight = (md: string, text: string) => {
  return md.replace(text, text.split('\n').map(line => line.length > 0 ? `\`${line}\`` : '').join('\n'))
}
</script>

<template>
  <TableRow>
    <TableCell>{{ props.order + 1 }}</TableCell>
    <TableCell class="!min-w-[400px]">{{ props.item.text }}</TableCell>
    <TableCell class="!min-w-[250px]">
      <Popover v-for="section in referencedIn" :key="section.id">
        <PopoverTrigger>
          <Badge class="!max-w-[250px]" variant="outline">{{ section.title }}</Badge>
        </PopoverTrigger>
        <PopoverContent>
          <Markdown class="!max-w-[600px] !max-h-[400px] overflow-y-auto p-4" :markdown="highlight(section.markdown, props.item.basis)" />
        </PopoverContent>
      </Popover>
    </TableCell>
    <TableCell class="!min-w-[250px]">
      <Popover v-for="section in addressedIn" :key="section.id">
        <PopoverTrigger>
          <Badge class="!max-w-[250px]" variant="outline">{{ section.title }}</Badge>
        </PopoverTrigger>
        <PopoverContent>
          <Markdown class="!max-w-[600px] !max-h-[400px] overflow-y-auto p-4" :markdown="section.paragraphs.map(p => p.markdown).join('\n')" />
        </PopoverContent>
      </Popover>
    </TableCell>
    <TableCell class="!min-w-[400px]">
      <Popover>
        <PopoverTrigger>
          <Paragraph class="!max-w-[400px] !max-h-[100px] overflow-y-hidden p-4 text-left text-overflow-ellipsis">{{ props.item.answer }}</Paragraph>
        </PopoverTrigger>
        <PopoverContent>
          <Markdown class="!max-w-[600px] !max-h-[400px] overflow-y-auto p-4" :markdown="props.item.answer ?? ''" />
        </PopoverContent>
      </Popover>
    </TableCell>
  </TableRow>
</template>

<style scoped lang="scss"></style>