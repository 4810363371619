<script setup lang="ts">
import type { HTMLAttributes } from 'vue'

const props = defineProps<{
  class?: HTMLAttributes['class']
}>()
</script>

<template>
  <span :class="['info-span', props.class]">
    <slot />
  </span>
</template>

<style scoped>
.info-span {
  margin-left: auto;
  font-size: var(--text-xs);
  letter-spacing: 0.05em;
  opacity: 0.6;
  color: hsl(var(--muted-foreground));
}
</style>