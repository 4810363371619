<script async setup lang="ts">
import Markdown from '@app/components/common/Markdown.vue'
import MDSmore from '@app/components/response/MDSmore.vue'
import { computed } from 'vue';
import type { ResponseSectionTree } from '@app/state/response';
import Rows from '@app/components/ui/flex/Rows.vue';

const props = defineProps<{
  section: ResponseSectionTree
}>()

const markdown = computed(() => '# ' + props.section.title + '\n\n' + props.section.paragraphs.map(p => p.markdown).join('\n'))
</script>

<template>
  <Rows margin="0 0 50px 0">
    <Markdown :markdown="markdown" />
  </Rows>
  <MDSmore :sections="section.children" />
</template>