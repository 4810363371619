<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import { Primitive, type PrimitiveProps } from 'radix-vue'
import { type ButtonVariants, buttonVariants } from '.'

interface Props extends PrimitiveProps {
  variant?: ButtonVariants['variant']
  size?: ButtonVariants['size']
  class?: HTMLAttributes['class']
}

const props = withDefaults(defineProps<Props>(), {
  as: 'button',
})
</script>

<template>
  <Primitive
    :as="as"
    :as-child="asChild"
    :class="['button', buttonVariants({ variant, size }), props.class]"
  >
    <div class="icon">
      <slot name="icon" />
    </div>
    <slot />
  </Primitive>
</template>

<style scoped lang="scss">
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: calc(var(--radius) - 2px);
  font-weight: 500;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  cursor: pointer;
}

.button:focus-visible {
  outline: 2px solid hsl(var(--ring));
  outline-offset: 2px;
}

.button:disabled {
  opacity: 0.5;
  pointer-events: none;
}

/* Default variant */
.button.default {
  background-color: hsl(var(--primary));
  color: hsl(var(--primary-foreground));
}

.button.default:hover {
  background-color: hsl(var(--primary) / 0.9);
}

/* Secondary variant */
.button.secondary {
  background-color: hsl(var(--secondary));
  color: hsl(var(--secondary-foreground));
}

.button.secondary:hover {
  background-color: hsl(var(--secondary) / 0.8);
}

/* Destructive variant */
.button.destructive {
  background-color: hsl(var(--destructive));
  color: hsl(var(--destructive-foreground));
}

.button.destructive:hover {
  background-color: hsl(var(--destructive) / 0.9);
}

/* Outline variant */
.button.outline {
  border: 1px solid hsl(var(--input));
  background-color: transparent;
  color: hsl(var(--foreground));
}

.button.outline:hover {
  background-color: hsl(var(--accent));
  color: hsl(var(--accent-foreground));
}

/* Ghost variant */
.button.ghost {
  background-color: transparent;
  color: hsl(var(--foreground));
}

.button.ghost:hover {
  background-color: hsl(var(--accent));
  color: hsl(var(--accent-foreground));
}

/* Link variant */
.button.link {
  background-color: transparent;
  color: hsl(var(--primary));
  text-decoration: underline;
}

.button.link:hover {
  color: hsl(var(--primary) / 0.8);
}

/* Size variants */
.button.default {
  height: 40px;
  padding: 0 16px;
  font-size: var(--text-xs);
}

.button.sm {
  height: 36px;
  padding: 0 12px;
  font-size: var(--text-xs);
}

.button.lg {
  height: 44px;
  padding: 0 32px;
  font-size: var(--text-sm);
}

.button.icon {
  height: 40px;
  width: 40px;
  padding: 0;
}

.icon {
  height: 1em;
  width: 1em;
  margin-right: 0.5rem;
  &:empty {
    display: none;
  }
}
</style>