<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import {
  HoverCardContent,
  type HoverCardContentProps,
  HoverCardPortal,
  useForwardProps,
} from 'radix-vue'

const props = withDefaults(
  defineProps<HoverCardContentProps & { class?: HTMLAttributes['class'] }>(),
  {
    sideOffset: 4,
  },
)

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props
  return delegated
})

const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <HoverCardPortal>
    <HoverCardContent
      v-bind="forwardedProps"
      :class="['hover-card-content', props.class]"
    >
      <slot />
    </HoverCardContent>
  </HoverCardPortal>
</template>

<style scoped>
:deep(.hover-card-content) {
  z-index: 50;
  width: 16rem;
  border-radius: var(--radius);
  border: 1px solid hsl(var(--border));
  background-color: hsl(var(--popover));
  color: hsl(var(--popover-foreground));
  padding: 1rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  outline: none;
}

:deep(.hover-card-content[data-state="open"]) {
  animation: fadeIn 150ms ease-out, zoomIn 150ms ease-out;
}

:deep(.hover-card-content[data-state="closed"]) {
  animation: fadeOut 150ms ease-in, zoomOut 150ms ease-in;
}

:deep(.hover-card-content[data-side="bottom"]) {
  animation: slideFromTop 150ms ease-out;
}

:deep(.hover-card-content[data-side="left"]) {
  animation: slideFromRight 150ms ease-out;
}

:deep(.hover-card-content[data-side="right"]) {
  animation: slideFromLeft 150ms ease-out;
}

:deep(.hover-card-content[data-side="top"]) {
  animation: slideFromBottom 150ms ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes zoomIn {
  from { transform: scale(0.95); }
  to { transform: scale(1); }
}

@keyframes zoomOut {
  from { transform: scale(1); }
  to { transform: scale(0.95); }
}

@keyframes slideFromTop {
  from { transform: translateY(-0.5rem); }
  to { transform: translateY(0); }
}

@keyframes slideFromRight {
  from { transform: translateX(0.5rem); }
  to { transform: translateX(0); }
}

@keyframes slideFromLeft {
  from { transform: translateX(-0.5rem); }
  to { transform: translateX(0); }
}

@keyframes slideFromBottom {
  from { transform: translateY(0.5rem); }
  to { transform: translateY(0); }
}
</style>