<script async setup lang="ts">
import EditableText from '@app/components/common/EditableText.vue';
import Markdown from '@app/components/common/Markdown.vue';
import Smore from '@app/components/response/Smore.vue';
import Reference from '@app/components/response/Reference.vue';
import EditResponse from '@app/components/response/EditResponse.vue';
import { createChild, createSibling, deleteSection, recalculateReferences, updateSection, writeResponse, type ResponseSectionTree } from '@app/state/response';
import { ref, computed } from '@vue/reactivity';
import Paragraph from '@app/components/typography/Paragraph.vue';
import Small from '@app/components/typography/Small.vue';
import Button from '@app/components/ui/button/Button.vue'
import Card from '@app/components/ui/card/Card.vue';
import { Separator } from '@app/components/ui/separator'

import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@app/components/ui/tabs"
import { Icon } from '@iconify/vue';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@app/components/ui/collapsible'
import Rows from '@app/components/ui/flex/Rows.vue'
import Columns from '@app/components/ui/flex/Columns.vue';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
  ContextMenuShortcut,
  ContextMenuSub,
  ContextMenuSubTrigger,
  ContextMenuSubContent,
} from '@app/components/ui/context-menu'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogClose,
  DialogTrigger,
} from "@app/components/ui/dialog"
import { isStaff } from '@app/state/auth';

const props = defineProps<{
  section: ResponseSectionTree
  rainbow: string[]
}>()

const n_paragraphs = ref(props.section.paragraphs.length + '')
const paragraphs = ref('')

const shiftUp = () => updateSection(props.section.id, {
  ...props.section,
  order: props.section.order - 1,
  paragraphs: undefined
})
const shiftDown = () => updateSection(props.section.id, {
  ...props.section,
  order: props.section.order + 1,
  paragraphs: undefined
})
const editTitle = () => updateSection(props.section.id, {
  ...props.section,
  title: props.section.title,
  paragraphs: undefined
})
const editPoints = () => updateSection(props.section.id, {
  ...props.section,
  points: props.section.points,
  paragraphs: undefined
})
const editParagraphs = () => updateSection(props.section.id, {
  ...props.section,
  paragraphs: parseInt(n_paragraphs.value),
})
const color = computed(() => props.rainbow[0])

const handleParagraphCount = async () => {
  await updateSection(props.section.id, {paragraphs: parseInt(paragraphs.value)})
  await writeResponse(props.section.id)
}
</script>

<template>
  <ContextMenu>
    <ContextMenuTrigger>
      <Rows>
        <Card>
          <Columns padding="14px 15px 14px 13px">
            <Columns>
              <Collapsible type="single">
                <Columns flex="1">
                  <CollapsibleTrigger :value="section.id" :key="section.id" class="text-left">
                    <Columns>
                      <Columns flex="1">
                        <Columns width="fit-content">
                          <EditableText @submit="editTitle" v-model="section.title" />
                        </Columns>
                      </Columns>
                      <Columns width="40px">
                        <Button variant="secondary" size="icon">
                          <Icon icon="lucide:chevrons-up-down" />
                        </Button>
                      </Columns>
                    </Columns>
                  </CollapsibleTrigger>
                </Columns>
                <CollapsibleContent :value="section.id">
                  <Tabs default-value="outline" class="tabs-root">
                    <Columns margin="5px 0 0 0">
                      <TabsList>
                        <TabsTrigger value="outline">
                          <Icon height="20px" icon="lucide:table-of-contents" />
                        </TabsTrigger>
                        <TabsTrigger value="content">
                          <Icon height="20px" icon="lucide:text" />
                        </TabsTrigger>
                      </TabsList>
                    </Columns>

                    <Rows>
                      <TabsContent value="outline">
                        <Paragraph>Obligations</Paragraph>
                        <Small>
                          <EditableText @submit="editPoints" v-model="section.points" multiline />
                        </Small>
                        <Paragraph>References</Paragraph>
                        <Reference v-for="reference in section.references" :reference="reference" />
                      </TabsContent>
                      <TabsContent value="content">
                        <Paragraph>Paragraphs</Paragraph>
                        <Small>
                          <Markdown v-for="paragraph in section.paragraphs" :key="paragraph.id"
                            :markdown="paragraph.markdown" />
                        </Small>
                      </TabsContent>
                    </Rows>
                  </Tabs>
                </CollapsibleContent>
              </Collapsible>
            </Columns>
          </Columns>
        </Card>
      </Rows>
    </ContextMenuTrigger>
    <ContextMenuContent>
      <ContextMenuItem @click="() => createSibling(section.id)">
        Add below
        <ContextMenuShortcut>
          <Icon icon="lucide:plus" />
        </ContextMenuShortcut>
      </ContextMenuItem>
      <ContextMenuItem @click="() => createChild(section.id)">
        Add child
        <ContextMenuShortcut>
          <Icon icon="lucide:plus" />
        </ContextMenuShortcut>
      </ContextMenuItem>
      <ContextMenuItem @click="() => shiftUp()">
        Shift up
        <ContextMenuShortcut>
          <Icon icon="lucide:chevron-up" />
        </ContextMenuShortcut>
      </ContextMenuItem>
      <ContextMenuItem @click="() => shiftDown()">
        Shift down
        <ContextMenuShortcut>
          <Icon icon="lucide:chevron-down" />
        </ContextMenuShortcut>
      </ContextMenuItem>
      <ContextMenuItem @click="() => recalculateReferences(section.id)">
        <span class="mr-2">Re-Reference</span>
        <ContextMenuShortcut>
          <Icon icon="lucide:refresh-ccw" />
        </ContextMenuShortcut>
      </ContextMenuItem>
      <ContextMenuItem @click="() => writeResponse(section.id)">
        Regenerate
        <ContextMenuShortcut>
          <Icon icon="lucide:pencil" />
        </ContextMenuShortcut>
      </ContextMenuItem>
      <ContextMenuItem @click="() => deleteSection(section.id)">
        Delete
        <ContextMenuShortcut>
          <Icon icon="lucide:trash" />
        </ContextMenuShortcut>
      </ContextMenuItem>
      <Dialog>
        <DialogTrigger as-child>
          <ContextMenuItem @select.prevent>
            <span>Paragraphs</span>
            <ContextMenuShortcut>
              <Icon icon="lucide:pilcrow"/>
            </ContextMenuShortcut>
          </ContextMenuItem>
        </DialogTrigger>
        <DialogContent class="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Paragraph Count</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            Enter the number of paragraphs:
            <EditableText multiline v-model="paragraphs"/>
            <br />
            <DialogClose>
              <Button
                @click="handleParagraphCount"
                variant="default" class="absolute bottom-4 right-4"
              >
                <template #icon>
                  <Icon icon="lucide:check" />
                </template>
                Confirm
              </Button>
            </DialogClose>
          </DialogDescription>
        </DialogContent>
      </Dialog>   
    </ContextMenuContent>
  </ContextMenu>
  <Rows v-if="section.children.length > 0">
    <div @click="() => createChild(section.id)" class="add-divider">
      <Separator label="+" />
    </div>
    <Rows class="branch" padding="0px 0px 0px 10px">
      <Smore :rainbow="rainbow.slice(1,)" :sections="section.children" :parent-id="section.id" />
    </Rows>
  </Rows>
  <div @click="() => createSibling(section.id)" class="add-divider">
    <Separator label="+" />
  </div>
</template>

<style scoped lang="scss">
.branch {
  border-left: 1px solid hsl(var(--secondary));
  border-left-color: v-bind(color);
}

.add-divider {
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  padding: 10px;
  margin: 0px;

  &:hover {
    opacity: 1;
  }
}
</style>
