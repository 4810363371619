<script setup lang="ts">
import { CollapsibleContent, type CollapsibleContentProps } from 'radix-vue'

const props = defineProps<CollapsibleContentProps>()
</script>

<template>
  <CollapsibleContent v-bind="props" class="collapsible-content">
    <slot />
  </CollapsibleContent>
</template>

<style scoped>
.collapsible-content {
  overflow: hidden;
  transition: all;
}

.collapsible-content[data-state="closed"] {
  animation: collapsible-up 0.2s ease-out;
}

.collapsible-content[data-state="open"] {
  animation: collapsible-down 0.2s ease-out;
}

@keyframes collapsible-up {
  from {
    height: var(--radix-collapsible-content-height);
  }
  to {
    height: 0;
  }
}

@keyframes collapsible-down {
  from {
    height: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
  }
}
</style>