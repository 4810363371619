<script lang="ts" setup>
import "@govpro-ai/dwarfhaven"
import { Icon } from '@iconify/vue'
import { colorMode } from '@app/state/common'
import Rows from '@app/components/ui/flex/Rows.vue'
import { Toaster } from '@app/components/ui/sonner'

console.log(colorMode.state.value)
</script>

<template>
  <div class="mobile-warning">
    <Rows height="100vh">
      <Rows middle center flex="1" gap="20" class="text-center">
        <Icon icon="lucide:expand" height="4em" />
        <Large>Please use a larger screen size.</Large>
      </Rows>
    </Rows>
  </div>

  <div class="main">
    <Toaster />
    <router-view />
  </div>
</template>

<style lang="scss" scoped>
.main {
  height: 100%;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.mobile-warning {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 100;
  background-color: #000;
  color: var(--primary-font-color);
}

.mobile-warning span {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.mobile-warning span img {
  width: 35px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .mobile-warning {
    display: none;
  }
}
</style>