<script async setup lang="ts">
import { watch } from 'vue';
import { ref } from '@vue/reactivity';
import type { Maybe } from '@Heirloom/common';
import Markdown from '@app/components/common/Markdown.vue';
import { Badge } from '@app/components/ui/badge';
import { Icon } from '@iconify/vue'
import { getReferencePage, referencePage } from '@app/state/reference';
import { Card } from '@app/components/ui/card';
import Rows from '@app/components/ui/flex/Rows.vue';
import Columns from '@app/components/ui/flex/Columns.vue';
import { Button } from '@app/components/ui/button';
import Large from '@app/components/typography/Large.vue';
import {
  ContextMenu,
  ContextMenuTrigger,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuShortcut,
} from '@app/components/ui/context-menu';
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from '@app/components/ui/collapsible';

const props = defineProps<{
  page: {
    id: string
    pageNumber: number
    markdown: Maybe<string>
    tags: string[]
  }
  total: number
}>()

const open = ref(true)
const showImage = ref(false)
const imageLoaded = ref(false)
const currentPageId = ref<string | null>(null)

const loadImage = async () => {
  currentPageId.value = props.page.id
  imageLoaded.value = false
  if (referencePage.value?.id !== props.page.id) {
    await getReferencePage(props.page.id)
  }
  if (referencePage.value?.image) {
    imageLoaded.value = true
  }
}

const toggleImage = async () => {
  if (!showImage.value) {
    await loadImage()
  }
  showImage.value = !showImage.value
}

watch(() => referencePage.value?.image, () => {
  if (referencePage.value?.id === props.page.id) {
    imageLoaded.value = true
  }
})

watch(() => props.page.id, () => {
  if (currentPageId.value !== props.page.id) {
    imageLoaded.value = false
  }
})
</script>

<template>
  <ContextMenu>
    <ContextMenuTrigger>
      <Card>
        <Columns padding="20px">
          <Collapsible v-model:open="open" type="single">
            <Columns flex="1">
              <CollapsibleTrigger :key="page.id" class="text-left">
                <Columns spread center>
                  <Columns flex="1">
                    Page {{ page.pageNumber + 1 }} / {{ total }}
                  </Columns>
                  <Columns width="30px">
                    <Button variant="ghost" size="icon" @click.stop.prevent="toggleImage">
                      <Icon :icon="showImage ? 'lucide:letter-text' : 'lucide:image'" />
                    </Button>
                  </Columns>
                </Columns>
              </CollapsibleTrigger>
            </Columns>
            <CollapsibleContent :value="page.id">
              <Rows v-if="showImage">
                <Columns gap="20">
                  <Rows flex="1">
                    <Markdown :markdown="page.markdown ?? ''" />
                  </Rows>
                  <Rows flex="1">
                    <template v-if="currentPageId === page.id">
                      <img v-if="imageLoaded && referencePage?.id === page.id" class="page-img"
                        :src="referencePage.image" crossorigin="anonymous" />
                    </template>
                  </Rows>
                </Columns>
              </Rows>
              <Markdown v-else :markdown="page.markdown ?? ''" />
            </CollapsibleContent>
          </Collapsible>
        </Columns>
      </Card>
    </ContextMenuTrigger>
    <ContextMenuContent>
      <ContextMenuItem>
        <span>Copy</span>
        <ContextMenuShortcut>
          <Icon icon="lucide:copy" />
        </ContextMenuShortcut>
      </ContextMenuItem>
    </ContextMenuContent>
  </ContextMenu>
</template>

<style scoped lang="scss">
.page-img {
  width: 100%;
  height: auto;
  background-color: white;
  border-radius: 5px;
}
</style>