<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import { TagsInputInput, type TagsInputInputProps, useForwardProps } from 'radix-vue'

const props = defineProps<TagsInputInputProps & { class?: HTMLAttributes['class'] }>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <TagsInputInput v-bind="forwardedProps" :class="['tags-input-input', props.class]" />
</template>

<style scoped>
.tags-input-input {
  font-szie: var(--text-sm);
  min-height: 1.25rem;
  flex: 1;
  background-color: transparent;
  padding: 0 0.25rem;
}

.tags-input-input:focus {
  outline: none;
}
</style>