import { io } from "socket.io-client"
import {
    pubsub, receiver,
    type Maybe, type Endpoint, type Request, type Response
} from "@Heirloom/common"
import { toast } from 'vue-sonner'
import { ref } from "@vue/reactivity"
import Logger from "@app/utils/logger"
import { useColorMode } from "@vueuse/core"
const Log = new Logger("Heirloom")

export const version = '3.0.85-govpro'

//? Common state
export const slug: Maybe<string> =
    window.location.href.match(/\/tomato/) ? "admin" :
    window.location.href.match(/\/[tb]\/([^\/]+)/)?.[1].split('#')[0].split('?')[0]


//? Socket API
export const socket = io(
    (window.location.href.includes('localhost') || window.location.href.includes('.patriot.govpro.ai')) ?
        'http://localhost:10917'
        : 'https://' + window.location.host + ':10920'
)
//@ts-ignore
window.socket = socket
export const connected = ref<boolean>(false)
socket.on("connect", () => {
    connected.value = true
    Log.success("Socket is connected.")
})
socket.on("disconnect", () => {
    connected.value = false
    Log.error("Socket has disconnected.")
})
// @ts-ignore
export const sock = pubsub(socket, io)
export const BIND = receiver(sock)
export const error = ref<Maybe<string>>(null)
BIND("error", ({ }) => e => {
    error.value = e.error
    Log.error(e.error)
    if (e.error === "Your session has timed out.") connected.value = false
    toast.error(e.error)
})


//? REST API
const base = (
    (window.location.href.includes('localhost') || window.location.href.includes('.patriot.govpro.ai')) ?
        'http://localhost:10917'
        : 'https://' + window.location.host + ':10920'
)
export const API = async <E extends Endpoint>(e: E, q: Request<E>) => {
    const s = await fetch(base + e, {
        method: "POST",
        body: JSON.stringify(q),
        headers: { "Content-Type": "application/json" }
    })
    if (s.status === 200) return await s.json() as Response<E>
    try {
        const e = await s.json()
        if (e.message) Log.error(e.message + '\n' + e.stacktrace)
        else Log.error(`Failed to POST ${e}, got ${s.status}:`, e)
        throw e.message
    } catch {
        Log.error(`Failed to POST ${e}, got ${s.status}: (failed to parse error)`)
        throw "Failed to parse error"
    }
}

export const colorMode = useColorMode()

//? Utilities
export const support = () => window.open('mailto:alex@govpro.ai')
export const sleep = (ms: number) => new Promise(s => setTimeout(s, ms))
