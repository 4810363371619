<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import { TagsInputItem, type TagsInputItemProps, useForwardProps } from 'radix-vue'

const props = defineProps<TagsInputItemProps & { class?: HTMLAttributes['class'] }>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <TagsInputItem v-bind="forwardedProps" :class="['tags-input-item', props.class]">
    <slot />
  </TagsInputItem>
</template>

<style scoped>
.tags-input-item {
  display: flex;
  height: 1.25rem;
  align-items: center;
  border-radius: calc(var(--radius) - 2px);
  background-color: hsl(var(--secondary));
}

.tags-input-item[data-state="active"] {
  ring: 2px solid hsl(var(--ring));
  ring-offset: 2px;
  ring-offset-color: hsl(var(--background));
}
</style>