<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import {
  DropdownMenuSubContent,
  type DropdownMenuSubContentEmits,
  type DropdownMenuSubContentProps,
  useForwardPropsEmits,
} from 'radix-vue'

const props = defineProps<DropdownMenuSubContentProps & { class?: HTMLAttributes['class'] }>()
const emits = defineEmits<DropdownMenuSubContentEmits>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props
  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <DropdownMenuSubContent
    v-bind="forwarded"
    :class="['dropdown-menu-sub-content', props.class]"
  >
    <slot />
  </DropdownMenuSubContent>
</template>

<style scoped>
:deep(.dropdown-menu-sub-content) {
  z-index: 50;
  min-width: 8rem;
  overflow: hidden;
  border-radius: 0.375rem;
  border: 1px solid hsl(var(--muted));
  background-color: hsl(var(--background));
  color: hsl(var(--foreground));
  padding: 0.25rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

:deep(.dropdown-menu-sub-content[data-state="open"]) {
  animation: fadeIn 0.1s ease-out, zoomIn 0.1s ease-out;
}

:deep(.dropdown-menu-sub-content[data-state="closed"]) {
  animation: fadeOut 0.1s ease-in, zoomOut 0.1s ease-in;
}

:deep(.dropdown-menu-sub-content[data-side="bottom"]) {
  animation: slideFromTop 0.1s ease-out;
}

:deep(.dropdown-menu-sub-content[data-side="left"]) {
  animation: slideFromRight 0.1s ease-out;
}

:deep(.dropdown-menu-sub-content[data-side="right"]) {
  animation: slideFromLeft 0.1s ease-out;
}

:deep(.dropdown-menu-sub-content[data-side="top"]) {
  animation: slideFromBottom 0.1s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes zoomIn {
  from { transform: scale(0.95); }
  to { transform: scale(1); }
}

@keyframes zoomOut {
  from { transform: scale(1); }
  to { transform: scale(0.95); }
}

@keyframes slideFromTop {
  from { transform: translateY(-0.5rem); }
  to { transform: translateY(0); }
}

@keyframes slideFromRight {
  from { transform: translateX(0.5rem); }
  to { transform: translateX(0); }
}

@keyframes slideFromLeft {
  from { transform: translateX(-0.5rem); }
  to { transform: translateX(0); }
}

@keyframes slideFromBottom {
  from { transform: translateY(0.5rem); }
  to { transform: translateY(0); }
}
</style>