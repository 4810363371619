<script lang="ts" setup>
</script>

<template>
  <a class="btn-primary glow-on-hover">
    <slot></slot>
  </a>
</template>

<style lang="scss">
.btn-primary {
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  user-select: none;
  vertical-align: top;
  text-align: center;
  height: 30px;
  border-radius: var(--primary-border-radius);
  gap: 8px;
  font-size: 14px;
  line-height: 20px;
  padding-left: 12px;
  padding-right: 12px;
  background: linear-gradient(45deg, #5b83ff, #b941c8);
  color: white !important;
  position: relative;
  font-weight: 500;
  width: fit-content;
  margin-top: 3px;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 5px;
  letter-spacing: .3px;
  transition: .1s;
  img {
    width: 16px;
    margin-top: -2px;
    margin-right: 2px;
  }
  &:hover {
    animation: load 1200ms linear infinite;
    background-size: 150% 150%;
    transition: .1s;
    scale: 1.03;
  }
}
</style>
