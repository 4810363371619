<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import type { ComboboxGroupProps } from 'radix-vue'
import { ComboboxGroup, ComboboxLabel } from 'radix-vue'

const props = defineProps<ComboboxGroupProps & {
  class?: HTMLAttributes['class']
  heading?: string
}>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})
</script>

<template>
  <ComboboxGroup v-bind="delegatedProps" :class="['combobox-group', props.class]">
    <ComboboxLabel v-if="heading" class="combobox-label">
      {{ heading }}
    </ComboboxLabel>
    <slot />
  </ComboboxGroup>
</template>

<style scoped>
.combobox-group {
  overflow: hidden;
  padding: 0.25rem;
  color: hsl(var(--foreground));
}

.combobox-group :deep([cmdk-group-heading]) {
  padding: 0.5rem;
  font-size: var(--text-xs);
  font-weight: 500;
  color: hsl(var(--muted-foreground));
}

.combobox-label {
  padding: 0.375rem 0.5rem;
  font-size: var(--text-xs);
  font-weight: 500;
  color: hsl(var(--muted-foreground));
}
</style>