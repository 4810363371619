<script lang="ts" setup>
import Bid from '@app/components/core/Bid.vue'
import Command from '@app/components/core/Command.vue'
import Snow from '@app/components/common/Snow.vue'
import { Group } from '@app/state/commands';
import { colorMode } from '@app/state/common';
import { onUnmounted } from 'vue';

Group("Interface", onUnmounted)
  .command({
    icon: "lucide:moon",
    label: "Force Dark Mode",
    perform: () => (colorMode.value = "dark"),
  })
  .command({
    icon: "lucide:sun",
    label: "Force Light Mode",
    perform: () => (colorMode.value = "light"),
  })
  .compile()
</script>

<template>
  <div class="main">
    <Command />
    <Bid />
    <Snow />
  </div>
</template>

<style scoped>
.main {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

@media (min-width: 768px) {
  .main {
    flex-direction: row;
  }
}
</style>