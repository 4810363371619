import { ref } from "@vue/reactivity"
import { colorMode } from "@app/state/common"
import type { onUnmounted } from 'vue';

export interface Command {
  icon: string
  label: string
  perform: () => void | any | Promise<any>
}

export interface Group {
  heading: string
  commands: Command[]
}

export const groups = ref<Group[]>()

const library: {
  [group: string]: {
    [command: string]: Command
  }
} = {}

const compile = () => {
  groups.value = Object.keys(library).map((heading) => ({
    heading,
    commands: Object.values(library[heading]),
  }))
}

export const Group = (heading: string, evict: typeof onUnmounted) => {
  if (!library[heading]) library[heading] = {}

  const command = (cmd: Command) => {
    library[heading][cmd.label] = cmd
    return { command, compile }
  }
  const _evict = () => delete library[heading]
  evict(_evict)

  return { command, compile }
}
