<script lang="ts" setup>
import { useMagicKeys } from '@vueuse/core'
import { watch } from 'vue';
const keys = useMagicKeys()

const props = defineProps<{
  size: 'small' | 'medium' | 'large' | 'fullscreen'
  onHide: () => void
}>()

const esc = keys['Escape']
watch(esc, props.onHide)
</script>

<template>
  <div :class="{
    'modal': true,
    'small': size == 'small',
    'medium': size == 'medium',
    'large': size == 'large',
    'fullscreen': size == 'fullscreen',
  }">
    <a @click="props.onHide">X Close</a>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal {
  background-color: hsl(var(--background));
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: unset;
  max-height: calc(100% - 60px);
  width: 90%;
  height: fit-content;
  border-radius: var(--primary-border-radius);
  box-shadow: inset 0 0 0 1px var(--secondary-background-color);
  overflow: visible;
  padding: 20px;
  color: var(--primary-font-color);
  text-align: center;
}

.small {
  max-width: 300px;
}

.medium {
  max-width: 700px;
}

.large {
  max-width: calc(100% - 50px);
  width: calc(100% - 50px);
  max-height: 900px;
  height: calc(100% - 50px);
}

.fullscreen {
  border-radius: 0 !important;
  padding: 5%;
}

.content {
  border-radius: var(--primary-border-radius);
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.esc {
  position: absolute;
  top: 0;
  margin-right: 0;
  margin-top: -40px;
  font-size: 14px;
  left: 50%;
  transform: translate(-50%, 0%);
  text-align: center;
  white-space: nowrap;
  color: var(--primary-font-color);
}

.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--modal-backdrop);
  z-index: 100;
  backdrop-filter: blur(5px) !important;
}
</style>
