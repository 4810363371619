<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import {
  ContextMenuSubTrigger,
  type ContextMenuSubTriggerProps,
  useForwardProps,
} from 'radix-vue'
import { Icon } from '@iconify/vue'

const props = defineProps<ContextMenuSubTriggerProps & { class?: HTMLAttributes['class'], inset?: boolean }>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <ContextMenuSubTrigger
    v-bind="forwardedProps"
    :class="['context-menu-sub-trigger', { 'inset': inset }, props.class]"
  >
    <slot />
    <Icon icon="radix-icons:chevron-right" class="chevron-icon" />
  </ContextMenuSubTrigger>
</template>

<style scoped>
.context-menu-sub-trigger {
  display: flex;
  cursor: default;
  user-select: none;
  align-items: center;
  border-radius: 0.125rem;
  padding: 0.375rem 0.5rem;
  font-size: var(--text-sm);
  outline: none;
}

.context-menu-sub-trigger:focus {
  background-color: hsl(var(--accent));
  color: hsl(var(--accent-foreground));
}

.context-menu-sub-trigger[data-state="open"] {
  background-color: hsl(var(--accent));
  color: hsl(var(--accent-foreground));
}

.context-menu-sub-trigger.inset {
  padding-left: 2rem;
}

.chevron-icon {
  margin-left: auto;
  height: 1rem;
  width: 1rem;
}
</style>