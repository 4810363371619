<template>
  <p class="text-sm text-muted">
    <slot />
  </p>
</template>

<style scoped>
.text-muted {
  color: hsl(var(--muted-foreground));
}
</style>