<script setup lang="ts">
import { useMagicKeys } from "@vueuse/core"
import { ref, watch } from "vue"
import {
  Command,
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@app/components/ui/command"
import { Icon } from "@iconify/vue"
import { groups } from "@app/state/commands";

const open = ref(false)

const keys = useMagicKeys()
const CmdK = keys["Cmd+K"]
const Esc = keys["Escape"]
const toggle = (b: boolean) => (open.value = b)
watch(CmdK, (v) => v && toggle(true))
watch(Esc, (v) => v && toggle(false))

const d = (task: () => void | any | Promise<any>) => () =>
  !(open.value = false) && task()
</script>

<template>
  <CommandDialog class="command" :open="open" @update:open="toggle">
    <CommandInput placeholder="Type a command or search..." />
    <CommandList>
      <CommandEmpty>No results found.</CommandEmpty>
      <template v-for="group in groups">
        <CommandGroup :heading="group.heading">
          <CommandItem @click="() => d(i.perform)()" v-for="i in group.commands" :value="i.label">
            <Icon class="h-4 w-4 mr-2" :icon="i.icon" />
            {{ i.label }}
          </CommandItem>
        </CommandGroup>
        <CommandSeparator />
      </template>
    </CommandList>
  </CommandDialog>
</template>

<style scoped lang="scss">
.command {
  width: 90%;
  max-height: 50%;
}
</style>