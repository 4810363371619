<script async setup lang="ts">
import Marshmallow from '@app/components/common/Marshmallow.vue';
import { ref } from '@vue/reactivity';
import { chat } from '@app/state/chat';
import { status } from '@app/state/scaling';
import Button from '@app/components/ui/button/Button.vue'
import Small from '@app/components/typography/Small.vue';
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@app/components/ui/alert"
import Columns from '@app/components/ui/flex/Columns.vue';
import Rows from '@app/components/ui/flex/Rows.vue';
import { Icon } from '@iconify/vue'
import Large from '@app/components/typography/Large.vue';
import Textfield from '@app/components/ui/textfield/Textfield.vue';
import { App, app, tab, tabs } from '@app/state/view';
import router from '@app/router';
import { slug } from '@app/state/common';

const query = ref('')
const openChat = () => {
  const conversation = chat(query.value)
  tabs.value.push({ name: 'New Chat', type: 'chat', id: conversation })
  query.value = ''
  router.push(`/t/${slug}/chat/${conversation}`)
}
</script>

<template>
  <Marshmallow :ms="500">
    <Rows style="width: 100%" height="100%" center middle>
      <Columns maxWidth="800px" padding="25px" middle center>
        <Rows center middle gap="20" margin="0 0 100px 0">
          <Rows gap="20">
            <Rows>
              <Rows middle center margin="10px">
              <Icon icon="lucide:message-circle" height="5em" />
              </Rows>
              <Large class="text-center">Chat with all of your past performance using your custom AI.</Large>
              <Small class="text-center">Your proposals train only your model, staying secure in your private
                environment.</Small>
            </Rows>
            <Alert v-if="status === 'cold'" variant="destructive">
              <Icon icon="lucide:triangle-alert" />
              <AlertTitle>The system is cold and will take longer to respond.</AlertTitle>
              <AlertDescription>
                You can use the ❄️ button at the top of the sidebar to warm it.
              </AlertDescription>
            </Alert>
            <Alert v-if="status === 'warming'" variant="destructive">
              <Icon icon="lucide:triangle-alert" />
              <AlertTitle>The system is currently warming up...</AlertTitle>
              <AlertDescription>
                You can expect this to take 1-2 minutes.
              </AlertDescription>
            </Alert>
          </Rows>
          <Rows>
            <Columns gap='10'>
              <Columns flex="1">
                <Textfield v-model="query" @submit="openChat" placeholder="Ask a question to your library">
                </Textfield>
              </Columns>
              <Columns width="70px">
                <Button variant="default" @click="openChat">
                  <Icon icon="lucide:arrow-up" />
                </Button>
              </Columns>
            </Columns>
          </Rows>
        </Rows>
      </Columns>
    </Rows>
  </Marshmallow>
</template>

<style scoped lang="scss"></style>