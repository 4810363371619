<template>
  <div :class="['card-footer', props.class]">
    <slot />
  </div>
</template>

<script setup lang="ts">
import type { HTMLAttributes } from 'vue'

const props = defineProps<{
  class?: HTMLAttributes['class']
}>()
</script>

<style scoped>
.card-footer {
  display: flex;
  align-items: center;
  padding: 24px;
  padding-top: 0;
}
</style>