<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import {
  ContextMenuItemIndicator,
  ContextMenuRadioItem,
  type ContextMenuRadioItemEmits,
  type ContextMenuRadioItemProps,
  useForwardPropsEmits,
} from 'radix-vue'
import { Icon } from '@iconify/vue'

const props = defineProps<ContextMenuRadioItemProps & { class?: HTMLAttributes['class'] }>()
const emits = defineEmits<ContextMenuRadioItemEmits>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <ContextMenuRadioItem
    v-bind="forwarded"
    :class="['context-menu-radio-item', props.class]"
  >
    <span class="indicator-wrapper">
      <ContextMenuItemIndicator>
        <Icon icon="radix-icons:dot-filled" class="indicator-icon" />
      </ContextMenuItemIndicator>
    </span>
    <slot />
  </ContextMenuRadioItem>
</template>

<style scoped>
.context-menu-radio-item {
  position: relative;
  display: flex;
  cursor: default;
  user-select: none;
  align-items: center;
  border-radius: 0.125rem;
  padding: 0.375rem 0.5rem 0.375rem 2rem;
  font-size: var(--text-sm);
  outline: none;
}

.context-menu-radio-item:focus {
  background-color: hsl(var(--accent));
  color: hsl(var(--accent-foreground));
}

.context-menu-radio-item[data-disabled] {
  pointer-events: none;
  opacity: 0.5;
}

.indicator-wrapper {
  position: absolute;
  left: 0.5rem;
  display: flex;
  height: 0.875rem;
  width: 0.875rem;
  align-items: center;
  justify-content: center;
}

.indicator-icon {
  height: 1rem;
  width: 1rem;
  fill: currentColor;
}
</style>