<script async setup lang="ts">
import ComplianceTable from '@app/components/compliance/Table.vue';
import { compliance, loadCompliance } from '@app/state/compliance';
import { loadResponse } from '@app/state/response';
import { solicitation } from '@app/state/solicitation';
import { ref, watch, computed } from 'vue';
import Rows from '@app/components/ui/flex/Rows.vue';
import Large from '@app/components/typography/Large.vue';

const isMandatoryOpen = ref(true)
const isQualificationsOpen = ref(true)
const isKnowledgeOpen = ref(true)
const isExperienceOpen = ref(true)
const isOtherOpen = ref(true)

watch(solicitation, sol => sol && loadCompliance(sol.id))
if (solicitation.value) loadCompliance(solicitation.value?.id)
watch(solicitation, sol => sol && loadResponse(sol.id))
if (solicitation.value) loadResponse(solicitation.value?.id)


const Mandatory = computed(() => (compliance.value ?? [])
  .filter(c => c.category?.toLowerCase() === 'mandatory' || c.category?.toLowerCase() === 'go/no go')
  .sort((a, b) => a.id.localeCompare(b.id))
)
const Qualifications = computed(() => (compliance.value ?? [])
  .filter(c => c.category?.toLowerCase() === 'qualifications')
  .sort((a, b) => a.id.localeCompare(b.id))
)
const Knowledge = computed(() => (compliance.value ?? [])
  .filter(c => c.category?.toLowerCase() === 'knowledge')
  .sort((a, b) => a.id.localeCompare(b.id))
)
const Experience = computed(() => (compliance.value ?? [])
  .filter(c => c.category?.toLowerCase() === 'experience')
  .sort((a, b) => a.id.localeCompare(b.id))
)
const Other = computed(() => (compliance.value ?? [])
  .filter(c => c.category?.toLowerCase() === 'other')
  .sort((a, b) => a.id.localeCompare(b.id))
)
</script>

<template>
  <Rows class="wide" padding="20px" gap="20" scroll>
    <Rows class="wide" v-if="Mandatory.length > 0">
      <Large>
        Mandatory Requirements (GO/NO GO)
      </Large>
      <ComplianceTable :items="Mandatory" />
    </Rows>

    <Rows class="wide" v-if="Qualifications.length > 0">
      <Large>
        Qualifications
      </Large>
      <ComplianceTable :items="Qualifications" />
    </Rows>

    <Rows class="wide" v-if="Knowledge.length > 0">
      <Large>
        Knowledge
      </Large>
      <ComplianceTable :items="Knowledge" />
    </Rows>

    <Rows class="wide" v-if="Experience.length > 0">
      <Large>
        Experience
      </Large>
      <ComplianceTable :items="Experience" />
    </Rows>

    <Rows class="wide" v-if="Other.length > 0">
      <Large>
        Other
      </Large>
      <ComplianceTable :items="Other" />
    </Rows>
  </Rows>
</template>

<style lang="scss" scoped>
.wide {
  width: min-content;
}
</style>