<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import {
  DialogClose,
  DialogContent,
  type DialogContentEmits,
  type DialogContentProps,
  DialogOverlay,
  DialogPortal,
  useForwardPropsEmits,
} from 'radix-vue'
import { Icon } from '@iconify/vue'

const props = defineProps<DialogContentProps & { class?: HTMLAttributes['class'] }>()
const emits = defineEmits<DialogContentEmits>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <DialogPortal>
    <DialogOverlay class="dialog-overlay">
      <DialogContent
        :class="['dialog-content', props.class]"
        v-bind="forwarded"
        @pointer-down-outside="(event) => {
          const originalEvent = event.detail.originalEvent;
          const target = originalEvent.target as HTMLElement;
          if (originalEvent.offsetX > target.clientWidth || originalEvent.offsetY > target.clientHeight) {
            event.preventDefault();
          }
        }"
      >
        <slot />

        <DialogClose class="dialog-close">
          <Icon icon="radix-icons:cross-2" class="close-icon" />
          <span class="sr-only">Close</span>
        </DialogClose>
      </DialogContent>
    </DialogOverlay>
  </DialogPortal>
</template>

<style scoped>
.dialog-overlay {
  position: fixed;
  inset: 0;
  z-index: 50;
  display: grid;
  place-items: center;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.dialog-overlay[data-state="open"] {
  animation: fadeIn 200ms ease-out;
}

.dialog-overlay[data-state="closed"] {
  animation: fadeOut 200ms ease-in;
}

.dialog-content {
  position: relative;
  z-index: 50;
  display: grid;
  width: 100%;
  max-width: 32rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  gap: 1rem;
  border: 1px solid hsl(var(--border));
  background-color: hsl(var(--background));
  padding: 1.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  transition-duration: 200ms;
}

@media (min-width: 640px) {
  .dialog-content {
    border-radius: 0.5rem;
  }
}

@media (min-width: 768px) {
  .dialog-content {
    width: 100%;
  }
}

.dialog-close {
  position: absolute;
  top: 1rem;
  outline: none;
  border: none;
  right: 1rem;
  padding: 0.125rem;
  transition: background-color 0.2s;
  border-radius: 0.375rem;
}

.dialog-close:hover {
  background-color: hsl(var(--secondary));
}

.close-icon {
  width: 1rem;
  height: 1rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>