<script setup lang="ts">
import { type ResponseSectionTree } from "@app/state/response"
import Section from "@app/components/response/Section.vue"
import Rows from "@app/components/ui/flex/Rows.vue"
import { Separator } from "@app/components/ui/separator"

defineProps<{
  sections: ResponseSectionTree[]
  parentId?: string
  rainbow: string[]
}>()
</script>

<template>
  <Rows v-for="section in sections">
    <Section :key="section.id" :section="section" :rainbow="rainbow" />
  </Rows>
</template>

<style scoped lang="scss">
.add-divider {
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  padding: 10px;
  margin: 5px;

  &:hover {
    opacity: 1;
  }
}
</style>
