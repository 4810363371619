<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import { type BadgeVariants, badgeVariants } from '.'
import { cn } from '@app/utils/tailwind'

const props = defineProps<{
  variant?: BadgeVariants['variant']
  class?: HTMLAttributes['class']
}>()
</script>

<template>
  <div :class="['badge', badgeVariants({ variant }), props.class]">
    <slot />
  </div>
</template>

<style scoped>
.badge {
  display: inline-flex;
  align-items: center;
  border-radius: calc(var(--radius) - 2px);
  border: 1px solid hsl(var(--border));
  padding: 0.225rem 0.625rem;
  font-size: var(--text-xs);
  font-weight: 600;
  line-height: 1;
  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  width: fit-content;
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.default {
  background-color: hsl(var(--primary));
  color: hsl(var(--primary-foreground));
}

.secondary {
  background-color: hsl(var(--secondary));
  color: hsl(var(--secondary-foreground));
}

.destructive {
  background-color: hsl(var(--destructive));
  color: hsl(var(--destructive-foreground));
}

.outline {
  background-color: transparent;
  color: hsl(var(--foreground));
  border: 1px solid hsl(var(--input));
  box-shadow: none;
  outline: none;
}
</style>