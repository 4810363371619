<script lang="ts" setup>
const props = defineProps<{
  isLabel?: boolean,
}>()
</script>

<template>
  <div class="card">
    <slot>

    </slot>
  </div>
</template>

<style lang="scss" scoped>
.card {
  padding: 12px;
  background-color: var(--primary-background-color);
  width: fit-content;
  margin-bottom: 10px;
  display: inline-flex;
  border-radius: var(--primary-border-radius);
  box-shadow: inset 0 0 0 1px var(--secondary-background-color);
}
</style>
