<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import {
  ContextMenuContent,
  type ContextMenuContentEmits,
  type ContextMenuContentProps,
  ContextMenuPortal,
  useForwardPropsEmits,
} from 'radix-vue'

const props = defineProps<ContextMenuContentProps & { class?: HTMLAttributes['class'] }>()
const emits = defineEmits<ContextMenuContentEmits>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props
  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <ContextMenuPortal>
    <ContextMenuContent
      v-bind="forwarded"
      :class="['context-menu-content', props.class]"
    >
      <slot />
    </ContextMenuContent>
  </ContextMenuPortal>
</template>

<style scoped>
:deep(.context-menu-content) {
  z-index: 50;
  min-width: 8rem;
  overflow: hidden;
  border-radius: var(--radius);
  border: 1px solid hsl(var(--border));
  background: hsl(var(--background));
  padding: 0.25rem;
  color: hsl(var(--popover-foreground));
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

:deep(.context-menu-content[data-state="open"]) {
  animation: fadeIn 150ms ease-out, zoomIn 150ms ease-out;
}

:deep(.context-menu-content[data-state="closed"]) {
  animation: fadeOut 150ms ease-in, zoomOut 150ms ease-in;
}

:deep(.context-menu-content[data-side="bottom"]) {
  animation: slideFromTop 150ms ease-out;
}

:deep(.context-menu-content[data-side="left"]) {
  animation: slideFromRight 150ms ease-out;
}

:deep(.context-menu-content[data-side="right"]) {
  animation: slideFromLeft 150ms ease-out;
}

:deep(.context-menu-content[data-side="top"]) {
  animation: slideFromBottom 150ms ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes zoomIn {
  from { transform: scale(0.95); }
  to { transform: scale(1); }
}

@keyframes zoomOut {
  from { transform: scale(1); }
  to { transform: scale(0.95); }
}

@keyframes slideFromTop {
  from { transform: translateY(-0.5rem); }
  to { transform: translateY(0); }
}

@keyframes slideFromRight {
  from { transform: translateX(0.5rem); }
  to { transform: translateX(0); }
}

@keyframes slideFromLeft {
  from { transform: translateX(-0.5rem); }
  to { transform: translateX(0); }
}

@keyframes slideFromBottom {
  from { transform: translateY(0.5rem); }
  to { transform: translateY(0); }
}
</style>