<script setup lang="ts">
import { HoverCardRoot, type HoverCardRootEmits, type HoverCardRootProps, useForwardPropsEmits } from 'radix-vue'

const props = defineProps<HoverCardRootProps>()
const emits = defineEmits<HoverCardRootEmits>()

const forwarded = useForwardPropsEmits(props, emits)
</script>

<template>
  <div class="header" v-bind="forwarded">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.header {
  height: 53px;
  border-bottom: 1px solid hsl(var(--border));
  padding: 8px;
}
</style>