<script setup lang="ts">
import type { HTMLAttributes } from 'vue'

const props = defineProps<{
  class?: HTMLAttributes['class']
}>()
</script>

<template>
  <span :class="['menu-item-shortcut', props.class]">
    <slot />
  </span>
</template>

<style scoped>
.menu-item-shortcut {
  margin-left: auto;
  font-size: var(--text-xs);
  letter-spacing: 0.05em;
  color: hsl(var(--muted-foreground));
}
</style>