<script async setup lang="ts">
import Badge from '@app/components/ui/badge/Badge.vue';
import Marshmallow from '@app/components/common/Marshmallow.vue';
import PerlinNoise from '@app/components/common/PerlinNoise.vue';
import StepTag from '@app/components/write/steps/Tag.vue'
import AddAttachment from '@app/components/write/AddAttachment.vue'
import ButtonSecondary from '@app/components/common/ButtonSecondary.vue';
import StepSolutioning from '@app/components/write/steps/Solutioning.vue'
import StepGenerate from '@app/components/write/steps/Generate.vue'
import StepCompliance from '@app/components/write/steps/Compliance.vue'
import StepReview from '@app/components/write/steps/Review.vue'
import EditOutline from '@app/components/response/EditOutline.vue'
import { Loader2 } from 'lucide-vue-next'
import Header from '@app/components/ui/header/Header.vue'
import Paragraph from '@app/components/typography/Paragraph.vue';
import Large from '@app/components/typography/Large.vue'
import Columns from '@app/components/ui/flex/Columns.vue';
import Rows from '@app/components/ui/flex/Rows.vue';
import { Icon } from '@iconify/vue'
import Button from '@app/components/ui/button/Button.vue'
import Markdown from '@app/components/common/Markdown.vue';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogClose,
  DialogTrigger,
} from '@app/components/ui/dialog'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@app/components/ui/popover'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@app/components/ui/dropdown-menu'
import {
  Table,
  TableBody,
  TableEmpty,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@app/components/ui/table'
import Stepper from '@app/components/ui/steps/Stepper.vue';
import { ref } from '@vue/reactivity';
import { computed, watch } from 'vue';
import { support } from '@app/state/common';
import { tab } from '@app/state/view';
import { autotagSolicitation, deleteSolicitation, downloadAttachment, downloadSolicitation, extractSolicitation, loadSolicitation, solicitation } from '@app/state/solicitation';
import { downloadOutline, editOutline, exportResponse, response, writeFullResponse } from '@app/state/response';
import { checkCompliance, downloadCompliance } from '@app/state/compliance';
import { addSolutioning, answerSolutioning, downloadSolutioning } from '@app/state/solutioning';

if (tab.value) loadSolicitation(tab.value.id)
watch(tab, (t) => {
  if (t) loadSolicitation(t.id)
})

enum Step {
  TAG = 1,
  SOLUTION = 2,
  OUTLINE = 3,
  COMPLIANCE = 4,
  GENERATE = 5
}
const maxStep = computed<Step>(() => {
  if (solicitation.value?.status === 'created') return Step.TAG
  if (solicitation.value?.status === 'processing') return Step.TAG
  if (solicitation.value?.status === 'parsed') return Step.TAG
  if (solicitation.value?.status === 'deleted') return Step.TAG
  if (solicitation.value?.status === 'tagging') return Step.TAG
  if (solicitation.value?.status === 'extraction') return Step.TAG
  if (solicitation.value?.status === 'solutioning') return Step.SOLUTION
  if (solicitation.value?.status === 'outlining') return Step.OUTLINE
  if (solicitation.value?.status === 'writing') return Step.OUTLINE
  if (solicitation.value?.status === 'checking') return Step.COMPLIANCE
  if (solicitation.value?.status === 'compliance') return Step.COMPLIANCE
  if (solicitation.value?.status === 'generating') return Step.GENERATE
  if (solicitation.value?.status === 'complete') return Step.GENERATE
  return Step.TAG
})
const step = ref<Step>(maxStep.value)

const hasResponse = computed(() => response.value.every(
  s => s.paragraphs.filter(p => p.markdown.trim().length === 0).length === 0
))

const interimSteps = ["created", "processing", "extraction", "generating", "checking"]
watch(solicitation, async (cur, old) => {
  if (!cur) return;
  if (!old) return (step.value = maxStep.value);
  if (interimSteps.includes(cur.status)) return;
  if (cur.status === old.status) return;
  if (cur.status === 'solutioning') {
    step.value = Step.SOLUTION
  }
  if (cur.status === 'outlining') {
    step.value = Step.OUTLINE
  }
  if (cur.status === 'compliance') {
    step.value = Step.COMPLIANCE
  }
  if (cur.status === 'complete') {
    step.value = Step.GENERATE
  }
})

const tokenCounts = computed(() => {
  const ret = {
    'total': 0,
    'requirements': 0,
    'submission-instructions': 0,
    'evaluation-criteria': 0
  }
  for (const section of solicitation.value?.sections ?? []) {
    section.tags.map(tag => {
      const tokens = Math.round(section.markdown.length / 4)
      if (tag === 'requirements') ret['requirements'] += tokens
      if (tag === 'submission-instructions') ret['submission-instructions'] += tokens
      if (tag === 'evaluation-criteria') ret['evaluation-criteria'] += tokens
    })
  }
  ret['total'] = ret['requirements'] + ret['submission-instructions'] + ret['evaluation-criteria']
  return ret
})

const copyName = () => window.copy(solicitation.value?.name ?? '')
const copyLink = () => window.copy(window.location.href)
const copyId = () => window.copy(solicitation.value?.id ?? '')
</script>

<template>
  <Rows v-if="solicitation">
    <Header>
      <Columns spread gap="40">
        <Columns left center flex="1">
          <Columns center width="fit-content">
            <Large class="one-line">
              {{ solicitation.name.replace('.pdf', '') }}
            </Large>
          </Columns>
          <Columns width="40px">
            <Popover>
              <PopoverTrigger as-child>
                <Button variant="link" size="icon">
                  <Icon icon="lucide:info" color="grey" />
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <Rows>
                  <Columns max-width="80vw">
                    <Rows padding="12px" flex="1">
                      {{ solicitation.title }}
                      <Paragraph>
                        {{ solicitation.bluf }}
                      </Paragraph>
                      <Rows max-height="400px" scroll>
                        <Table>
                          <TableHeader>
                            <TableRow>
                              <TableHead colspan="1" style="min-width: 150px;">Metadata</TableHead>
                              <TableHead colspan="1">Value</TableHead>
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            <template v-for="meta in solicitation!.meta">
                              <TableRow>
                                <TableCell colspan="1">
                                  {{ meta.key.capitalize() }}
                                </TableCell>
                                <TableCell colspan="1">
                                  <Columns max-width="550px" class="one-line">
                                    {{ meta.value }}
                                  </Columns>
                                </TableCell>
                              </TableRow>
                            </template>
                          </TableBody>
                        </Table>
                      </Rows>
                    </Rows>
                    <Columns width="300px" color="hsl(var(--muted))">
                      <Rows gap="10" middle margin="10px">
                        <img crossorigin="anonymous" :src="solicitation.thumbnail ?? '/assets/test.png'" height="230px" />
                        <Badge variant="default">
                          Status: {{ solicitation.status.capitalize() }}
                        </Badge>
                        <Badge variant="outline">
                          {{ solicitation.pages.length }} pages / {{ solicitation.sections.length }} sections
                        </Badge>
                        <Table v-if="solicitation.attachments.length > 0">
                          <TableHeader>
                            <TableRow>
                              <TableHead>Attachments</TableHead>
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            <TableRow v-for="attachment in solicitation.attachments">
                              <TableCell>
                                <Columns center gap="10">
                                  <Columns width="230px" class="one-line">
                                    {{ attachment.split('/').pop() }}
                                  </Columns>
                                  <Columns width="20px">
                                    <Button variant="link" size="icon" @click="downloadAttachment(attachment, solicitation.id)">
                                      <Icon class="text-indigo-600" icon="lucide:file-down" />
                                    </Button>
                                  </Columns>
                                </Columns>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Rows>
                    </Columns>
                  </Columns>
                </Rows>
              </PopoverContent>
            </Popover>
          </Columns>
        </Columns>

        <Columns width="fit-content" gap="10">
          <DropdownMenu>
            <DropdownMenuTrigger as-child>
              <Button variant="secondary">
                <span class="mr-4">Actions</span>
                <Icon icon="lucide:chevron-down" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuGroup v-if="step === Step.TAG">
                <AddAttachment />
                <DropdownMenuItem @click="autotagSolicitation">
                  <Icon icon="lucide:brain-circuit" class="mr-2" />
                  <span>Auto-tag</span>
                </DropdownMenuItem>
              </DropdownMenuGroup>
              <DropdownMenuGroup v-if="step === Step.SOLUTION">
                <DropdownMenuItem disabled>
                  <Icon icon="lucide:phone" class="mr-2" />
                  <span>Contact POC</span>
                </DropdownMenuItem>
                <DropdownMenuItem @click="addSolutioning">
                  <Icon icon="lucide:plus" class="mr-2" />
                  <span>Add Question</span>
                </DropdownMenuItem>
                <DropdownMenuItem @click="answerSolutioning">
                  <Icon icon="lucide:brain-circuit" class="mr-2" />
                  <span>Ask your AI SME</span>
                </DropdownMenuItem>
              </DropdownMenuGroup>
              <DropdownMenuGroup v-if="step === Step.OUTLINE">
                <Dialog>
                  <DialogTrigger as-child>
                    <DropdownMenuItem @select.prevent>
                      <Icon icon="lucide:pyramid" class="mr-2" />
                      Structure
                    </DropdownMenuItem>
                  </DialogTrigger>
                  <DialogContent>
                    <Rows max-height="80vh" scroll>
                      <Markdown :markdown="solicitation?.structure ?? 'No analysis made (yet).'" />
                    </Rows>
                  </DialogContent>
                </Dialog>
                <EditOutline />
                <DropdownMenuItem @click="downloadOutline">
                  <Icon icon="lucide:file-spreadsheet" class="mr-2" />
                  <span>Export CSV</span>
                </DropdownMenuItem>
                <DropdownMenuItem @click="writeFullResponse">
                  <Icon icon="lucide:pen" class="mr-2" />
                  <span>{{ hasResponse ? 'Rewrite' : 'Write' }}</span>
                </DropdownMenuItem>
              </DropdownMenuGroup>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <DropdownMenuSub>
                  <DropdownMenuSubTrigger>
                    <Icon icon="lucide:download" class="mr-2" />
                    Download
                  </DropdownMenuSubTrigger>
                  <DropdownMenuPortal>
                    <DropdownMenuSubContent>
                      <DropdownMenuItem @click="downloadSolicitation">
                        <Icon icon="lucide:scroll-text" class="mr-2" />
                        <span>Solicitation</span>
                      </DropdownMenuItem>
                      <DropdownMenuItem @click="downloadOutline">
                        <Icon icon="lucide:list-ordered" class="mr-2" />
                        <span>Outline</span>
                      </DropdownMenuItem>
                      <DropdownMenuItem @click="downloadCompliance">
                        <Icon icon="lucide:list-todo" class="mr-2" />
                        <span>Compliance</span>
                      </DropdownMenuItem>
                      <DropdownMenuItem @click="exportResponse">
                        <Icon icon="lucide:file-text" class="mr-2" />
                        <span>Proposal</span>
                      </DropdownMenuItem>
                      <DropdownMenuItem @click="downloadSolutioning">
                        <Icon icon="lucide:file-down" class="mr-2" />
                        <span>Solutioning</span>
                      </DropdownMenuItem>
                    </DropdownMenuSubContent>
                  </DropdownMenuPortal>
                </DropdownMenuSub>
                <DropdownMenuSub>
                  <DropdownMenuSubTrigger>
                    <Icon icon="lucide:copy" class="mr-2" />
                    Copy
                  </DropdownMenuSubTrigger>
                  <DropdownMenuPortal>
                    <DropdownMenuSubContent>
                      <DropdownMenuItem @select="copyName">
                        <Icon icon="lucide:case-sensitive" class="mr-2" />
                        <span>Name</span>
                      </DropdownMenuItem>
                      <DropdownMenuItem @select="copyLink">
                        <Icon icon="lucide:link" class="mr-2" />
                        <span>Link</span>
                      </DropdownMenuItem>
                      <DropdownMenuItem @select="copyId">
                        <Icon icon="lucide:barcode" class="mr-2" />
                        <span>ProposalAI ID</span>
                      </DropdownMenuItem>
                    </DropdownMenuSubContent>
                  </DropdownMenuPortal>
                </DropdownMenuSub>
                <Dialog>
                  <DialogTrigger as-child>
                    <DropdownMenuItem @select.prevent>
                      <Icon icon="lucide:trash" class="text-red-500 mr-2" />
                      <span class="text-red-500">Delete</span>
                    </DropdownMenuItem>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Delete Solicitation</DialogTitle>
                    </DialogHeader>
                    <DialogDescription>
                      Any progress towards a draft will be lost. Are you sure that you want to proceed?
                      <br><br>
                      <DialogClose>
                        <Button @click="deleteSolicitation" variant="destructive">
                          <template #icon>
                            <Icon icon="lucide:trash" />
                          </template>
                          Delete
                        </Button>
                      </DialogClose>
                    </DialogDescription>
                  </DialogContent>
                </Dialog>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>

          <template v-if="!(interimSteps.includes(solicitation.status))">
            <template v-if="step === Step.TAG">
              <Button v-if="tokenCounts.requirements > 0" @click.stop.prevent="extractSolicitation">
                <template #icon>
                  <Icon icon="lucide:pencil" />
                </template>
                Solutioning
              </Button>
              <Dialog v-else>
                <DialogTrigger as-child>
                  <Button>
                    <template #icon>
                      <Icon icon="lucide:pencil" />
                    </template>
                    Solutioning
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Warning</DialogTitle>
                  </DialogHeader>
                  <DialogDescription>
                    <Paragraph>
                      You haven't tagged any sections as Requirements. You can still proceed, but you may not get the best results.
                    </Paragraph>
                    <br />
                    <DialogClose>
                      <Button @click="extractSolicitation">
                        <template #icon>
                          <Icon icon="lucide:pencil" />
                        </template>
                        Solutioning
                      </Button>
                    </DialogClose>
                  </DialogDescription>
                </DialogContent>
              </Dialog>
            </template>
            <Button v-if="step === Step.SOLUTION" @click.stop.prevent="editOutline">
              <template #icon>
                <Icon icon="lucide:pen" />
              </template>
              Outline
            </Button>
            <Button v-if="step === Step.OUTLINE" @click.stop.prevent="checkCompliance">
              <template #icon>
                <Icon icon="lucide:list-checks" />
              </template>
              Check Compliance
            </Button>
            <Button v-if="step === Step.COMPLIANCE" @click.stop.prevent="writeFullResponse">
              <template #icon>
                <Icon icon="lucide:pencil" />
              </template>
              Generate Draft
            </Button>
            <Button v-if="step === Step.GENERATE" @click.stop.prevent="exportResponse" :disabled="!hasResponse">
              <template #icon>
                <Icon icon="lucide:file-down" />
              </template>
              Download Draft
            </Button>
          </template>
          <template v-else>
            <Button disabled variant="default">
              <Loader2 class="w-4 h-4 mr-2 animate-spin" />
              Please wait
            </Button>
          </template>
        </Columns>
      </Columns>
    </Header>
    <Stepper :steps="[
      { num: Step.TAG, name: 'Tagging' },
      { num: Step.SOLUTION, name: 'Solutioning' },
      { num: Step.OUTLINE, name: 'Outlining' },
      { num: Step.COMPLIANCE, name: 'Compliance' },
      { num: Step.GENERATE, name: 'Generate' }
    ]" v-model="step" :completed="maxStep - 1" />

    <Rows max-height="calc(100vh - 100px)">
      <StepTag v-if="step === Step.TAG" />
      <StepSolutioning v-if="step === Step.SOLUTION" />
      <StepGenerate v-if="step === Step.OUTLINE" />
      <StepCompliance v-if="step === Step.COMPLIANCE" />
      <StepReview v-if="step === Step.GENERATE" />
    </Rows>
  </Rows>

  <Rows v-else middle center flex="1" gap="20" class="text-center">
    <Marshmallow :ms="1000">
      <Icon icon="lucide:file-warning" height="4em" />
      <Large>An error occurred while loading this opportunity.</Large>
      <Button variant="link" class="support" @click="support">Please contact support if the issue persists.</Button>
    </Marshmallow>
  </Rows>
</template>

<style scoped lang="scss">
.one-line {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}
</style>
