<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import { TabsContent, type TabsContentProps } from 'radix-vue'

const props = defineProps<TabsContentProps & { class?: HTMLAttributes['class'] }>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props
  return delegated
})
</script>

<template>
  <TabsContent
    :class="['tabs-content', props.class]"
    v-bind="delegatedProps"
  >
    <slot />
  </TabsContent>
</template>

<style scoped>
.tabs-content {
  margin-top: 0.5rem;
  outline: none;
  transition: ring 0.2s ease-out;
  display: flex;
  flex-direction: column;
}
</style>