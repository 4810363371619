<script lang="ts" setup>
import defs from '@app/utils/icon-definitions'
defineProps<{
  name?: string & keyof typeof defs
  color?: "blue" | "red" | "grey" | "green" | "white" | "normal" | "error" | "gray" | "original"
}>();
</script>

<template>
  <img :src="defs[name!]" :class="color" draggable="false" />
</template>

<style lang="scss" scoped>
img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.blue {
  filter: invert(22%) sepia(63%) saturate(3131%) hue-rotate(230deg) brightness(89%) contrast(86%);
}

.red {
  filter: invert(54%) sepia(82%) saturate(5141%) hue-rotate(322deg) brightness(70%) contrast(96%);
}

.error {
  filter: invert(16%) sepia(82%) saturate(7141%) hue-rotate(347deg) brightness(80%) contrast(106%);
}

.grey {
  filter: invert(61%) sepia(17%) saturate(543%) hue-rotate(211deg) brightness(90%) contrast(81%);
  opacity: .5;
}

.gray {
  opacity: .5;
  filter: invert(61%) sepia(17%) saturate(543%) hue-rotate(211deg) brightness(90%) contrast(81%);
}

.normal {
  filter: var(--icon-filter);
  opacity: 1.0;
}

.green{
  filter: invert(40%) sepia(86%) saturate(1505%) hue-rotate(66deg) brightness(83%) contrast(96%);
}
.white {
  filter: invert(1);
}
</style>
