<script setup lang="ts">
import { Icon } from '@iconify/vue'

defineProps<{
  steps: {
    num: number
    name: string
    icon?: string
  }[]
  modelValue: number
  completed: number
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', step: number): void
}>()

const changeStep = (step: number) => emit('update:modelValue', step)
</script>

<template>
  <div class="stepper">
    <div class="step-container" v-for="(step, i) in steps" :key="step.num" @click="changeStep(step.num)"
      :class="{ active: modelValue === step.num, completed: step.num <= completed + 1 }">
      <div class="divider" v-if="step.num > 1">
        <!-- right pointing triangle contained within the divider -->
        <svg v-if="modelValue === step.num - 1" class="triangle filled" width="28" height="44" viewBox="0 0 10 10" fill="none" stroke="currentColor">
          <path d="M-1 -3.5 L10 5 L-1 13.5 Z" stroke="currentColor" stroke-width="0.5" />
        </svg>
        <svg v-else-if="completed === steps[i - 2]?.num" class="triangle completed" width="28" height="44" viewBox="0 0 10 10" fill="none" stroke="currentColor">
          <path d="M0 -2.855 L10 5 L0 12.855" stroke="currentColor" stroke-width="0.5" />
        </svg>
        <svg v-else class="triangle" width="28" height="44" viewBox="0 0 10 10" fill="none" stroke="currentColor">
          <path d="M0 -2.855 L10 5 L0 12.855" stroke="currentColor" stroke-width="0.5" />
        </svg>
      </div>
      <div class="step" :class="{ first: i === 0 }">
        <div class="title">
          {{ step.name }}
          <Icon color="rgb(16 185 129)" class="icon" v-if="step.num <= completed" icon="lucide:check-check" />
        </div>
        <Icon class="icon" :icon="step.icon ?? `tabler:circle-number-${step.num}`" />
      </div>
    </div>
    <div class="step-container last">
      <div class="divider">
        <!-- right pointing triangle contained within the divider -->
        <svg v-if="modelValue === steps[steps.length - 1].num" class="triangle filled" width="28" height="46" viewBox="0 0 10 10" fill="none" stroke="currentColor">
          <path d="M0 -2.855 L10 5 L0 12.855 Z" stroke="currentColor" stroke-width="0.5" />
        </svg>
        <svg v-else class="triangle" width="28" height="44" viewBox="0 0 10 10" fill="none" stroke="currentColor">
          <path d="M0 -2.855 L10 5 L0 12.855" stroke="currentColor" stroke-width="0.5" />
        </svg>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.stepper {
  display: flex;
  flex-direction: row;
  gap: 0;
  width: 100%;
  user-select: none;
  cursor: pointer;

  .step-container {
    flex: 1;
    display: inline-flex;

    .first.step {
      padding-left: 30px;
    }

    &.last {
      width: 30px;
      flex: unset;
      .divider {
        border-top: none;
        border-bottom: none;
      }
    }

    .step {
      flex: 1;
      padding: 8px;
      border-top: 1px solid hsl(var(--border)/30%);
      border-bottom: 1px solid hsl(var(--border)/30%);
      color: hsl(var(--muted-foreground)/50%);
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: var(--text-sm);

      .title {
        display: inline-flex;
        align-items: center;
        gap: 4px;
      }

      .icon {
        width: 1.25em;
        height: 1.25em;
        color: hsl(var(--muted-foreground)/50%);
      }
    }
    .divider {
      width: 30px;
      color: hsl(var(--border)/30%);
      border-top: 1px solid hsl(var(--border)/30%);
      border-bottom: 1px solid hsl(var(--border)/30%);

      .triangle {
        fill: hsl(var(--background));

        &.filled {
          fill: hsl(var(--secondary-foreground));
          color: hsl(var(--secondary-foreground));
        }
        &.completed {
          color: hsl(var(--border));
        }
      }
    }

    &.completed {
      .step {
        border-top: 1px solid hsl(var(--border));
        border-bottom: 1px solid hsl(var(--border));
        color: hsl(var(--secondary-foreground));
        .icon {
          color: hsl(var(--secondary-foreground));
        }
      }

      .divider {
        border-top: 1px solid hsl(var(--border));
        border-bottom: 1px solid hsl(var(--border));
        color: hsl(var(--border));
      }
    }

    &.active {
      .step {
        border-top: 1px solid hsl(var(--secondary-foreground));
        border-bottom: 1px solid hsl(var(--secondary-foreground));
        color: hsl(var(--background));
        font-weight: 600;
        background-color: hsl(var(--secondary-foreground));
        .icon {
          color: hsl(var(--background));
        }
      }

      .divider {
        border-top: 1px solid hsl(var(--secondary-foreground));
        border-bottom: 1px solid hsl(var(--secondary-foreground));
        background-color: hsl(var(--secondary-foreground));
      }
    }
  }
}
</style>
