<script async setup lang="ts">
import { ref } from '@vue/reactivity'
import EditableText from '@app/components/common/EditableText.vue';
import { editOutline } from '@app/state/response';
import Button from '@app/components/ui/button/Button.vue';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogClose,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "@app/components/ui/dialog"
import { DropdownMenuItem } from '@app/components/ui/dropdown-menu';
import { Icon } from '@iconify/vue';

const instructions = ref('\n\n')
</script>

<template>
  <Dialog>
    <DialogTrigger as-child>
      <DropdownMenuItem>
        <Icon icon="lucide:rotate-cw" class="mr-2" />
        Revise
      </DropdownMenuItem>
    </DialogTrigger>
    <DialogContent class="sm:max-w-[425px]">
      <DialogHeader>
        <DialogTitle>Revise Outline</DialogTitle>
        <DialogDescription>
          Please enter instructions for how you'd like to edit the outline.
          <EditableText multiline v-model="instructions" />
        </DialogDescription>
      </DialogHeader>
      <DialogFooter>
        <Button type="submit" variant="default">
          <Button variant="default" @click="editOutline(instructions)" v-if="!!(instructions.trim())">
            <template #icon>
              <Icon icon="lucide:check" />
            </template>
            Revise Outline
          </Button>
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>

<style scoped lang="scss">
.edit-field {
  background-color: var(--modal-backdrop) !important;
  margin: 14px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
}
</style>
