<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import type { ComboboxSeparatorProps } from 'radix-vue'
import { ComboboxSeparator } from 'radix-vue'

const props = defineProps<ComboboxSeparatorProps & { class?: HTMLAttributes['class'] }>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})
</script>

<template>
  <ComboboxSeparator
    v-bind="delegatedProps"
    :class="['combobox-separator', props.class]"
  >
    <slot />
  </ComboboxSeparator>
</template>

<style scoped>
.combobox-separator {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
  height: 1px;
  background-color: hsl(var(--border));
}
</style>