<template>
  <h3 :class="['card-title', props.class]">
    <slot />
  </h3>
</template>

<script setup lang="ts">
import type { HTMLAttributes } from 'vue'

const props = defineProps<{
  class?: HTMLAttributes['class']
}>()
</script>

<style scoped>
.card-title {
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.025em;
}
</style>