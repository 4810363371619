<script async setup lang="ts">
import { Button } from '@app/components/ui/button'
import { Icon } from '@iconify/vue'
import { TOKEN, bind } from '@app/state/auth';
import { API, slug, version } from '@app/state/common';
import { googleTokenLogin } from "vue3-google-login"

const auth = async () => {
    if (!slug) throw new Error('Slug is required')
    const { access_token } = await googleTokenLogin()
    const { token } = await API('/auth/google', { accessToken: access_token, slug, version })
    TOKEN.value = token
    return bind()
}
</script>

<template>
    <Button variant="outline" type="button" @click="auth">
        <Icon icon="tabler:brand-google-filled" class="brand-icon" />
        Sign in with Google
    </Button>
</template>

<style lang="scss" scoped>
.brand-icon {
  margin-right: 0.5rem;
  height: 1rem;
  width: 1rem;
}
</style>