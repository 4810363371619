<script async setup lang="ts">
import Question from '@app/components/solutioning/Question.vue';
import MinField from '@app/components/common/MinField.vue';
import { solicitation } from '@app/state/solicitation';
import { loadSolutioning, solutioning } from '@app/state/solutioning';
import { ref, watch } from 'vue';
import Rows from '@app/components/ui/flex/Rows.vue';
import Columns from '@app/components/ui/flex/Columns.vue';
import { references, listReferences } from '@app/state/reference';
import { Alert, AlertDescription, AlertTitle } from '@app/components/ui/alert';
import { CircleAlert } from 'lucide-vue-next'

listReferences()

watch(solicitation, sol => sol && loadSolutioning(sol.id))
if (solicitation.value) loadSolutioning(solicitation.value?.id)

const query = ref<string>('')
</script>

<template>
  <Rows v-if="solicitation" padding="20px" scroll>
    <div>
      <Alert v-if="references.length <= 0">
        <CircleAlert class="h-4 w-4" />
        <AlertTitle>Action Required</AlertTitle>
        <AlertDescription>
          Please add references to your library.
        </AlertDescription>
      </Alert>
    </div>
    <Question v-for="item in solutioning" :item="item" />
  </Rows>
</template>

<style lang="scss" scoped>
.solutioning {
  display: flex;
  flex-direction: column;
  gap: 14px;

  overflow-y: auto;
  padding-bottom: 20px;
  padding-right: 10px;

  overflow: auto;
  height: calc(100vh - 175px) !important;
}
</style>