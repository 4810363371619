import { ref } from "@vue/reactivity"
import { sock, BIND } from "@app/state/common"
import Logger from "@app/utils/logger"
const Log = new Logger("Scaling", { bgColor: '#ffcc99' })

export const status = ref<'warming' | 'cooling' | 'warm' | 'cold'>('cold')

export const getScalingStatus = () => sock.pub('🧠/status', { })
BIND('🧠:status', () => (s) => {
  Log.info(`Scaling status: ${s.status}`)
  status.value = s.status
})

export const warm = () => sock.pub('🧠/warm', { })
export const cool = () => sock.pub('🧠/cool', { })

export const monitorScaling = () => {
  getScalingStatus()
  setInterval(getScalingStatus, 1000 * 60)
}