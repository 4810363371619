<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import { Separator, type SeparatorProps } from 'radix-vue'
import { Icon } from '@iconify/vue'

const props = defineProps<
  SeparatorProps & { class?: HTMLAttributes['class'], label?: string }
>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})
</script>

<template>
  <Separator
    v-bind="delegatedProps"
    :class="['separator', props.orientation === 'vertical' ? 'vertical' : 'horizontal', props.class]"
  >
    <span
      v-if="props.label"
      :class="['label', props.orientation === 'vertical' ? 'vertical' : 'horizontal']"
    >{{ props.label }}</span>
  </Separator>
</template>

<style scoped>
.separator {
  flex-shrink: 0;
  background-color: hsl(var(--border));
  position: relative;
}

.separator.vertical {
  width: 1px;
  height: 100%;
}

.separator.horizontal {
  height: 1px;
  width: 100%;
}

.label {
  font-size: var(--text-xs);
  color: hsl(var(--muted-foreground));
  background-color: hsl(var(--background));
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.label.vertical {
  width: 1px;
  padding: 0.25rem 0.5rem;
}

.label.horizontal {
  height: 1px;
  padding: 0.5rem 0.25rem;
}
</style>