<script async setup lang="ts">
import { ref, watch, computed } from '@vue/reactivity';
import Markdown from '@app/components/common/Markdown.vue';
import { Icon } from '@iconify/vue';
import type { LibraryDocument } from '@Heirloom/common';
import { disableReference } from '@app/state/reference';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@app/components/ui/collapsible'
import Rows from '@app/components/ui/flex/Rows.vue'
import Columns from '@app/components/ui/flex/Columns.vue';
import Checkbox from '@app/components/ui/checkbox/Checkbox.vue';
import Badge from '@app/components/ui/badge/Badge.vue';
import { Card } from '@app/components/ui/card'
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
  ContextMenuShortcut,
} from '@app/components/ui/context-menu'
import { nextTick } from 'vue';

const props = defineProps<{
  section: LibraryDocument['sections'][number] & {
    score?: string | number
  }
  modelValue: boolean
  onCheck?: () => void
  onShiftCheck?: () => void
}>()

const emits = defineEmits<{
  'update:modelValue': [boolean]
}>()

const copy = () => navigator.clipboard.writeText(props.section.markdown)
const open = ref(true)

const checked = ref(props.modelValue)
watch(checked, (isCheck, wasCheck) => {
  emits('update:modelValue', checked.value)
  if (isCheck != wasCheck) props.onCheck?.()
})

const onShiftCheck = async () => {
  await nextTick()
  if (checked.value) props.onShiftCheck?.()
}

const isChecked = computed(() => props.modelValue)
watch(isChecked, () => checked.value = isChecked.value)
</script>

<template>
  <ContextMenu>
    <ContextMenuTrigger>
      <Rows :class="section.enabled ? 'enabled' : 'disabled'">
        <Card>
          <Columns padding="14px 15px 0 13px">
            <Columns width="17px" middle margin="4px 6px 0 0">
              <Rows middle>
                <Checkbox v-model:checked="checked" @shift-check="onShiftCheck" />
              </Rows>
            </Columns>
            <Columns>
              <Collapsible v-model:open="open" type="single">
                <Columns flex="1">
                  <CollapsibleTrigger :value="section.id" :key="section.id" class="text-left">
                    {{ section.title }}
                    <Badge v-if="section.score" variant="secondary">{{ section.score }}</Badge>
                    <Icon icon="lucide:chevrons-up-down" />
                  </CollapsibleTrigger>
                </Columns>
                <CollapsibleContent :value="section.id">
                  <Markdown :markdown="section.markdown ?? ''" />
                </CollapsibleContent>
              </Collapsible>
            </Columns>
          </Columns>
        </Card>
      </Rows>
    </ContextMenuTrigger>
    <ContextMenuContent>
      <ContextMenuItem v-if="!(section.enabled)" @click="disableReference(section.id)">
        <span>Enable</span>
        <ContextMenuShortcut>
          <Icon icon="lucide:eye" />
        </ContextMenuShortcut>
      </ContextMenuItem>
      <ContextMenuItem v-else @click="disableReference(section.id)">
        <span>Disable</span>
        <ContextMenuShortcut>
          <Icon icon="lucide:eye-off" />
        </ContextMenuShortcut>
      </ContextMenuItem>
      <ContextMenuItem variant="ghost" @click="copy">
        <span>Copy</span>
        <ContextMenuShortcut>
          <Icon icon="lucide:copy" />
        </ContextMenuShortcut>
      </ContextMenuItem>
    </ContextMenuContent>
  </ContextMenu>
</template>

<style scoped lang="scss">
.disabled {
  opacity: 0.5;
}
</style>