import { ref } from "@vue/reactivity"
import type { LibraryDocument, LibraryDocumentMeta, Maybe } from "@Heirloom/common"
import { sock, BIND } from "@app/state/common"
import { app, tab, tabs, App } from "@app/state/view"
import Logger from "@app/utils/logger"
const Log = new Logger("Reference", { bgColor: '#ccff99' })


//? Load
export const reference = ref<Maybe<LibraryDocument>>()
export const loadReference = (id: string) => {
  if (id !== reference.value?.id) reference.value = null
  sock.pub('library/load', { id })
}
BIND('library:load', () => r => {
  r.sections = r.sections.sort((a, b) => a.sectionNumber - b.sectionNumber)
  reference.value = r
})

//? List
export const references = ref<LibraryDocumentMeta[]>([])
export const listReferences = () => sock.pub('library/list', {})
BIND('library:list', () => rs => references.value = rs.sort((a, b) => new Date(b.created).valueOf() - new Date(a.created).valueOf()))

//? Delta
BIND('△:library', () => r => r.id === reference.value?.id ? loadReference(r.id) : listReferences())

//? Create
const files: Record<string, File> = {}
export const createReference = async (file: File) => {
  Log.info(`Creating reference for <${file.name}>`, file)
  sock.pub('library/create', { filename: file.name })
  files[file.name] = file
}
BIND('library:upload', ({ pub }) => ({ filename, url, id }) => {
  if (!url) throw new Error("Did not receive a presigned URL.")
  const file = files[filename]
  if (!file) throw new Error("Do not have file to upload.")

  Log.log(`Received presigned URL. Beginning upload of <${filename}>`)
  const xhr = new XMLHttpRequest()
  xhr.onload = () => {
    Log.success(`Successfully uploaded <${filename}>`)
    Log.info(`Parsing reference ${id} <${filename}>`)
    delete files[filename]
    pub('library/parse', { id })
  }
  xhr.open('PUT', url)
  xhr.send(file)
  Log.log(`PUT <${filename}> → [${url.slice(0, 30)}...]`)
})

//? View page
export const getReferencePage = (pageId: string) => sock.pub('library/page/load', { id: reference.value!.id, pageId })
export const referencePage = ref<Maybe<{ image: string, id: string }>>()
BIND('library/page:load', ({ pub }) => ({ image, id }) => {
  referencePage.value = { image, id }
})

//? Delete
export const deleteReference = (id: Maybe<string>) => {
  if (!id || typeof id !== 'string') id = reference.value?.id;
  if (!id) return;
  sock.pub('library/delete', { id })
  tabs.value = tabs.value.filter(tab => tab.id !== id)
  if (tab.value?.id === id) {
    tab.value = undefined
  }
  if (app.value === App.TAB) app.value = App.LIBRARY
  reference.value = null
}
export const deleteReferenceById = (id: string) => {
  sock.pub('library/delete', { id })
  tabs.value = tabs.value.filter(tab => tab.id !== id)
  if (tab.value?.id === id) tab.value = undefined
}

//? Disable
export const disableReference = (sectionId: string) => {
  if (!reference.value) return;
  sock.pub('library/section/disable', { id: reference.value?.id, sectionId })
}

//? Download
export const downloadReference = (id: Maybe<string>) => {
  if (!id || typeof id !== 'string') id = reference.value?.id;
  if (!id) return;
  sock.pub('library/download', { id })
}
BIND('library:download', () => ({ url }) => {
  const a = document.createElement('a')
  a.href = url
  a.target = '_blank'
  a.click()
})

//? Chat
export const answer = ref<Maybe<string>>()
export const context = ref<LibraryDocument['sections']>([])
export const chatReference = (message: string) => {
  if (!reference.value) return;
  answer.value = ''
  context.value = []
  sock.pub('library/chat/single', { id: reference.value?.id, message })
}
BIND('library/chat:single', () => ({ messagePart, sections }) => {
  if (messagePart) answer.value += messagePart
  if (sections) {
    context.value.push(...sections)
  }
})

//? Retry
export const retryReference = (id: string) => sock.pub('library/parse', { id })