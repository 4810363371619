<script async setup lang="ts">
import { ref } from '@vue/reactivity';
import Markdown from '@app/components/common/Markdown.vue';
import { Icon } from '@iconify/vue';
import { Badge } from '@app/components/ui/badge';
import { solicitation, tagSection } from '@app/state/solicitation';
import { computed, nextTick, watch } from 'vue';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@app/components/ui/collapsible'
import Rows from '@app/components/ui/flex/Rows.vue'
import Columns from '@app/components/ui/flex/Columns.vue';
import Checkbox from '@app/components/ui/checkbox/Checkbox.vue';
import { Card } from '@app/components/ui/card'
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
  ContextMenuShortcut,
} from '@app/components/ui/context-menu'
import { Button } from '@app/components/ui/button'
import { Popover, PopoverContent, PopoverTrigger } from '@app/components/ui/popover'
import { TagsInput, TagsInputItem, TagsInputItemText, TagsInputItemDelete, TagsInputInput } from '@app/components/ui/tags-input'

const props = defineProps<{
  section: {
    id: string
    title: string
    markdown: string
    tags: string[]
    tokens: number
    score?: string | number | null
  }
  modelValue: boolean
  onCheck?: () => void
  onShiftCheck?: () => void
}>()

const emits = defineEmits<{
  'update:modelValue': [boolean]
}>()

const open = ref(props.section.tags.filter(
  tag => !(['none', 'other-requirements'].includes(tag.toLowerCase()))
).length > 0)

const copy = () => {
  navigator.clipboard.writeText(props.section.id)
}

const sectionTags = computed(() => props.section.tags.map(tag => tag.toLowerCase().replace(/ /gim, '-')))
const isRequirement = computed(() => {
  return sectionTags.value.includes('requirements')
})
const isSubmissionInstructions = computed(() => {
  return sectionTags.value.includes('submission-instructions')
})
const isEvaluationCriteria = computed(() => {
  return sectionTags.value.includes('evaluation-criteria')
})

const checked = ref(props.modelValue)
watch(checked, (isCheck, wasCheck) => {
  emits('update:modelValue', checked.value)
  if (isCheck != wasCheck) props.onCheck?.()
})

const onShiftCheck = async () => {
  await nextTick()
  if (checked.value) props.onShiftCheck?.()
}

const isChecked = computed(() => props.modelValue)
watch(isChecked, () => checked.value = isChecked.value)

const tag = (tag: "requirements" | "submission-instructions" | "evaluation-criteria") => {
  const t = tag.toLowerCase().replace(/ /gim, '-')
  const tags = sectionTags.value
  tagSection(props.section.id, tag)
}

const extraTags = ref(props.section.tags.filter(tag =>
  !(['none', 'other-requirements', 'requirements', 'submission-instructions', 'evaluation-criteria'].includes(tag.toLowerCase()))))
const initialTags = new Set(extraTags.value)

watch(extraTags, (tags) => {
  const s1 = new Set(tags)
  const s2 = initialTags
  ;[...s1].filter(t => !s2.has(t)).forEach(t => {
    initialTags.add(t)
    tagSection(props.section.id, t.toLowerCase())
  })
  ;[...s2].filter(t => !s1.has(t)).forEach(t => {
    initialTags.delete(t)
    tagSection(props.section.id, t.toLowerCase())
  })
}, { deep: true })
</script>

<template>
  <ContextMenu>
    <ContextMenuTrigger>
      <Rows>
        <Card>
          <Columns padding="15px">
            <Collapsible v-model:open="open" type="single">
              <Columns flex="1">
                <CollapsibleTrigger :value="section.id" :key="section.id">
                  <Columns gap="8">
                    <Columns width="17px" center>
                      <Checkbox v-model:checked="checked" @shift-check="onShiftCheck" />
                    </Columns>
                    <Columns flex="1" center class="one-line">
                      {{ section.title }}
                    </Columns>
                    <Popover>
                      <PopoverTrigger as-child>
                        <Badge :variant="extraTags.length > 0 ? 'default' : 'outline'" @click.stop.prevent>
                          <Icon icon="lucide:tag" />
                        </Badge>
                      </PopoverTrigger>
                      <PopoverContent as-child>
                        <TagsInput v-model="extraTags">
                          <TagsInputItem v-for="t in extraTags" :key="t" :value="t">
                            <TagsInputItemText />
                            <TagsInputItemDelete />
                          </TagsInputItem>
                          <TagsInputInput placeholder="Add a tag..." />
                        </TagsInput>
                      </PopoverContent>
                    </Popover>
                    <Badge :variant="isRequirement ? 'default' : 'outline'" @click.stop.prevent="tag('requirements')">
                      Requirements
                    </Badge>
                    <Badge :variant="isSubmissionInstructions ? 'default' : 'outline'"
                      @click.stop.prevent="tag('submission-instructions')">
                      Submission Instructions
                    </Badge>
                    <Badge :variant="isEvaluationCriteria ? 'default' : 'outline'"
                      @click.stop.prevent="tag('evaluation-criteria')">
                      Evaluation Criteria
                    </Badge>
                    <Badge v-if="section.score" variant="secondary">{{ (+(section.score) * 100).toFixed(2) }}%</Badge>
                    <Columns width="40px">
                      <Button variant="secondary" size="icon">
                        <Icon icon="lucide:chevrons-up-down" />
                      </Button>
                    </Columns>
                  </Columns>
                </CollapsibleTrigger>
              </Columns>
              <CollapsibleContent :value="section.id">
                <Markdown :markdown="section.markdown ?? ''" />
              </CollapsibleContent>
            </Collapsible>
          </Columns>
        </Card>
      </Rows>
    </ContextMenuTrigger>
    <ContextMenuContent>
      <ContextMenuItem @click="tag('requirements')">
        <span>Requirements</span>
        <ContextMenuShortcut>
          <Icon icon="tabler:square-letter-c-filled" />
        </ContextMenuShortcut>
      </ContextMenuItem>
      <ContextMenuItem @click="tag('submission-instructions')">
        <span class="mr-2">Submission Instructions</span>
        <ContextMenuShortcut>
          <Icon icon="tabler:square-letter-l-filled" />
        </ContextMenuShortcut>
      </ContextMenuItem>
      <ContextMenuItem @click="tag('evaluation-criteria')">
        <span>Evaluation Criteria</span>
        <ContextMenuShortcut>
          <Icon icon="tabler:square-letter-m-filled" />
        </ContextMenuShortcut>
      </ContextMenuItem>
      <ContextMenuItem @click="copy">
        <span>Copy</span>
        <ContextMenuShortcut>
          <Icon icon="lucide:copy" />
        </ContextMenuShortcut>
      </ContextMenuItem>
    </ContextMenuContent>
  </ContextMenu>
</template>

<style scoped lang="scss">
.one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>