<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import { TagsInputItemText, type TagsInputItemTextProps, useForwardProps } from 'radix-vue'

const props = defineProps<TagsInputItemTextProps & { class?: HTMLAttributes['class'] }>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <TagsInputItemText v-bind="forwardedProps" :class="['tags-input-item-text', props.class]" />
</template>

<style scoped>
.tags-input-item-text {
  padding: 0.125rem 0.5rem;
  font-szie: var(--text-sm);
  border-radius: 0.25rem;
  background-color: transparent;
}
</style>