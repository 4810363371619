<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import {
  PopoverContent,
  type PopoverContentEmits,
  type PopoverContentProps,
  PopoverPortal,
  useForwardPropsEmits,
} from 'radix-vue'
import { Icon } from '@iconify/vue'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<PopoverContentProps & { class?: HTMLAttributes['class'] }>(),
  {
    align: 'center',
    sideOffset: 4,
  },
)
const emits = defineEmits<PopoverContentEmits>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <PopoverPortal>
    <PopoverContent
      v-bind="{ ...forwarded, ...$attrs }"
      :class="['popover-content', props.class]"
    >
      <slot />
    </PopoverContent>
  </PopoverPortal>
</template>

<style scoped>
:deep(.popover-content) {
  z-index: 50;
  border-radius: var(--radius);
  border: 1px solid hsl(var(--border));
  background-color: hsl(var(--popover));
  color: hsl(var(--popover-foreground));
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  outline: none;
}

.popover-content[data-state="open"] {
  animation: fadeIn 0.2s ease-out;
}

.popover-content[data-state="closed"] {
  animation: fadeOut 0.2s ease-in;
}

.popover-content[data-side="bottom"] {
  animation: slideFromTop 0.2s ease-out;
}

.popover-content[data-side="left"] {
  animation: slideFromRight 0.2s ease-out;
}

.popover-content[data-side="right"] {
  animation: slideFromLeft 0.2s ease-out;
}

.popover-content[data-side="top"] {
  animation: slideFromBottom 0.2s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes slideFromTop {
  from { transform: translateY(-0.5rem); }
  to { transform: translateY(0); }
}

@keyframes slideFromRight {
  from { transform: translateX(0.5rem); }
  to { transform: translateX(0); }
}

@keyframes slideFromLeft {
  from { transform: translateX(-0.5rem); }
  to { transform: translateX(0); }
}

@keyframes slideFromBottom {
  from { transform: translateY(0.5rem); }
  to { transform: translateY(0); }
}
</style>