<script async setup lang="ts">
import Smore from '@app/components/response/Smore.vue';
import { solicitation } from '@app/state/solicitation';
import { loadResponse, responseTree } from '@app/state/response';
import { watch } from 'vue';
import Rows from '@app/components/ui/flex/Rows.vue';
import Columns from '@app/components/ui/flex/Columns.vue';
import { listReferences } from '@app/state/reference';
import { RAINBOW } from '@app/state/view';

watch(solicitation, sol => sol && loadResponse(sol.id))
if (solicitation.value) loadResponse(solicitation.value?.id)
listReferences()
</script>

<template>
  <Rows padding="20px" scroll>
    <Smore :rainbow="RAINBOW" :sections="responseTree" />
  </Rows>
</template>

<style lang="scss" scoped></style>
