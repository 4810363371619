<script async setup lang="ts">
import Markdown from '@app/components/common/Markdown.vue';
import EditableText from '@app/components/common/EditableText.vue';
import { ref, computed } from '@vue/reactivity';
import Button from '@app/components/ui/button/Button.vue'
import Muted from '@app/components/typography/Muted.vue';
import Columns from '@app/components/ui/flex/Columns.vue';
import Rows from '@app/components/ui/flex/Rows.vue';
import { Icon } from '@iconify/vue'
import Large from '@app/components/typography/Large.vue';
import Textfield from '@app/components/ui/textfield/Textfield.vue';
import ReferenceCard from '@app/components/chat/ReferenceCard.vue'
import Header from '@app/components/ui/header/Header.vue'
import { tab } from '@app/state/view';
import { type Conversation, chat, conversations } from '@app/state/chat';
import type { Maybe } from '@Heirloom/common';
import PerlinNoise from '@app/components/common/PerlinNoise.vue';
import { colorMode } from '@app/state/common';

const mode = computed(() => colorMode.state.value);

const query = ref('')
const isWaitingForResponse = computed(() => {
  if (!conversation.value) return false
  const messages = conversation.value.messages
  return messages.length > 0 && messages[messages.length - 1].type === 'query'
})

const sendMessage = () => {
  query.value = query.value.trim()
  if (!query.value) return
  chat(query.value, conversation.value?.id)
  query.value = ''
}

const conversation = computed<Maybe<Conversation>>(() => {
  const id = tab.value?.id
  if (!id) return null
  return conversations.value.find(c => c.id === id)
})

const download = () => {
  if (!conversation.value) return
  const blob = new Blob([conversation.value.messages.map(m => m.message).join('\n\n')], { type: 'text/plain' })
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = `${conversation.value.id}.txt`
  a.click()
}
</script>

<template>
  <Rows v-if="conversation && tab" style="width: 100%" height="100%" middle spread>
    <Rows flex="1">
      <Header>
        <Columns padding="0 10px" spread>
          <Rows center>
            <Large>
              <EditableText v-model="tab.name" />
            </Large>
          </Rows>
          <Columns width="135px">
            <Button variant="outline" @click="download">
              <template #icon>
                <Icon icon="lucide:download" />
              </template>
              Download
            </Button>
          </Columns>
        </Columns>
      </Header>

      <Rows scroll flex="1">
        <Rows center middle>
          <Columns max-width="900px" padding="25px">
            <Rows gap="10">
              <Rows v-for="(message, i) in conversation.messages" :key="`message-${i}`"
                :color="message.type === 'query' ? 'hsl(var(--primary-foreground))' : ''" gap="20" padding="25px">
                <template v-if="message.type === 'query'">
                  {{ message.message }}
                </template>
                <template v-else>
                  <Markdown v-if="message.message" :markdown="message.message" />
                  <Columns v-else>
                    <Columns width="20px" center>
                      <Muted>
                        <Icon icon="lucide:info" />
                      </Muted>
                    </Columns>
                    <Rows center>
                      <Muted>No response from AI.</Muted>
                    </Rows>
                  </Columns>
                  <ReferenceCard v-for="context in message.context" :section="context" />
                </template>
              </Rows>
              <Rows v-if="isWaitingForResponse" padding="25px" center middle>
                <Columns maxWidth="200px">
                  <PerlinNoise class="middle w-[100px]" :class="mode" />
                </Columns>
              </Rows>
            </Rows>
          </Columns>
        </Rows>
      </Rows>
    </Rows>

    <Columns max-width="900px" center>
      <Rows center middle>
        <Rows gap="5" padding="5px">
          <Columns gap='10'>
            <Columns flex="1">
              <Textfield v-model="query" @submit="sendMessage"
                placeholder="Talk to your entire library of past performance">
              </Textfield>
            </Columns>
            <Columns width="70px">
              <Button variant="default" @click="sendMessage">
                <Icon icon="lucide:arrow-up" />
              </Button>
            </Columns>
          </Columns>
          <Muted class="text-center">
            This AI has been trained on your library, but it can make mistakes.
            Please double-check critical information.
          </Muted>
        </Rows>
      </Rows>
    </Columns>
  </Rows>
</template>

<style scoped lang="scss"></style>