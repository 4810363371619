import { createApp } from '@vue/runtime-dom'
import App from '@app/App.vue'
import router from '@app/router'
import googleSSO from 'vue3-google-login'
import LottieAnimation from "lottie-web-vue";
import '@app/assets/css/style.css'

createApp(App)
	.use(router)
	.use(googleSSO, {
		clientId: '952436913778-dbtn579hkfcdhmlt01gm710cnn4m1lb6.apps.googleusercontent.com',
		idConfiguration: {
			client_id: '952436913778-dbtn579hkfcdhmlt01gm710cnn4m1lb6.apps.googleusercontent.com',
		}
	})
	.use(LottieAnimation)
	.mount('#app')
