<script async setup lang="ts">
import type { LibraryDocumentMeta } from '@Heirloom/common';
import { deleteReference, downloadReference, listReferences, references } from '@app/state/reference';
import { App, app, tab, tabs } from '@app/state/view';
import ReferenceCard from '@app/components/library/ReferenceCard.vue';
import Grid from '@app/components/ui/flex/Grid.vue'
import { slug } from '@app/state/common';
import router from '@app/router';
import {
  ContextMenu,
  ContextMenuTrigger,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuShortcut,
} from '@app/components/ui/context-menu';
import { Icon } from '@iconify/vue';

listReferences()

const openReference = (reference: LibraryDocumentMeta) => {
  const exists = tabs.value.find(tab => tab.type === 'reference' && tab.id === reference.id)
  if (!exists) tabs.value.push(
    {
      name: reference.name.replace('.pdf', ''),
      type: 'reference',
      id: reference.id,
      reference
    }
  )
  router.push(`/t/${slug}/library/${reference.id}`)
}
</script>

<template>
  <Grid item-width="220px" gap="35" margin="20px 0" v-if="references.length > 0">
    <ContextMenu v-for="reference in references" :key="reference.id">
      <ContextMenuTrigger>
        <ReferenceCard @click="openReference(reference)" :reference="reference" />
      </ContextMenuTrigger>
      <ContextMenuContent>
        <ContextMenuItem @click="downloadReference(reference.id)">
          <span>Download</span>
          <ContextMenuShortcut>
            <Icon icon="lucide:download" />
          </ContextMenuShortcut>
        </ContextMenuItem>
        <ContextMenuItem @click="deleteReference(reference.id)">
          <span>Delete</span>
          <ContextMenuShortcut>
            <Icon icon="lucide:trash" />
          </ContextMenuShortcut>
        </ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu>
  </Grid>
</template>

<style scoped lang="scss">
</style>