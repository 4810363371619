<script async setup lang="ts">
import { Icon } from '@iconify/vue'
import Badge from '@app/components/ui/badge/Badge.vue'
import Markdown from '@app/components/common/Markdown.vue'
import { references } from '@app/state/reference';
import type { LibraryDocument, LibraryDocumentMeta } from '@Heirloom/common';
import { ref, watch } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import { tabs } from '@app/state/view'
import { slug } from '@app/state/common'
import Button from '../ui/button/Button.vue';

const props = defineProps<{
  reference: LibraryDocument["sections"][number]
}>()

const referenceDoc = ref(references.value.find(r => r.id === props.reference.referenceId))
const expand = ref(false)
const router = useRouter()

const openReference = (referenceDoc: LibraryDocumentMeta) => {
  const exists = tabs.value.find(tab => tab.type === 'reference' && tab.id === referenceDoc.id);
  if (!exists) {
    tabs.value.push({
      name: referenceDoc.name.replace('.pdf', ''),
      type: 'reference',
      id: referenceDoc.id,
      reference: referenceDoc
    });
  }
  router.push(`/t/${slug}/library/${referenceDoc.id}`);
}
</script>

<template>
  <div class="reference" :key="reference.id">
    <div class="title" @click.stop.prevent="expand = !expand">
      <Badge style="display: unset; max-width: 500px">
        {{ referenceDoc?.name ?? referenceDoc?.title ?? reference.id }}
      </Badge>
      {{ reference.title }}
      <Icon :icon="expand ? 'lucide:chevron-down' : 'lucide:chevron-right'" />
    </div>
    <div v-if="expand" class="markdown">
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <span>{{ referenceDoc!.title }}</span>
        <Button variant="outline" @click.stop.prevent="openReference(referenceDoc!)">
          Open Reference
        </Button>
      </div>
      <Markdown class="content" :markdown="reference.markdown" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  user-select: none;
  gap: 2px;
  color: var(--primary-color);
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  background-color: var(--primary-background-color-accent);
  width: fit-content;
  padding: 2px 4px;
  border-radius: var(--primary-border-radius);

  img {
    width: 10px;
    margin-top: -1px;
  }
}

.markdown {
  margin-top: 8px;
  margin-left: 2px;
  padding-left: 8px;
  border-left: 1px solid var(--primary-background-color);
  .content {
    background-color: var(--secondary-background-color);
    color: var(--primary-font-color);
    border-radius: var(--primary-border-radius);
    padding: 8px;
    font-size: 12px !important;
  }
}
</style>