<script async setup lang="ts">
import PerlinNoise from '@app/components/common/PerlinNoise.vue';
import { ref, computed } from '@vue/reactivity';
import { onMounted } from 'vue';
import { colorMode } from '@app/state/common';

const mode = computed(() => colorMode.state.value);
const props = defineProps<{
    ms: number;
}>();

const visible = ref(true)
onMounted(() => {
    setTimeout(() => {
        visible.value = false;
    }, props.ms);
});
</script>

<template>
    <div class="loader" v-if="visible">
        <PerlinNoise class="middle" :class="mode" />
    </div>
    <slot v-else />
</template>

<style lang="scss" scoped>
.loader {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 400px;
    filter: hue-rotate(50deg) grayscale(100%);
}

.light {
    filter: hue-rotate(50deg) grayscale(100%) invert(1);
}
</style>