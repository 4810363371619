<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import { SplitterGroup, type SplitterGroupEmits, type SplitterGroupProps, useForwardPropsEmits } from 'radix-vue'

const props = defineProps<SplitterGroupProps & { class?: HTMLAttributes['class'] }>()
const emits = defineEmits<SplitterGroupEmits>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props
  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <SplitterGroup v-bind="forwarded" :class="['splitter-group', props.class]">
    <slot />
  </SplitterGroup>
</template>

<style scoped>
.splitter-group {
  display: flex;
  height: 100%;
  width: 100%;
}

.splitter-group[data-panel-group-direction="vertical"] {
  flex-direction: column;
}
</style>