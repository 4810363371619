<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import { SplitterResizeHandle, type SplitterResizeHandleEmits, type SplitterResizeHandleProps, useForwardPropsEmits } from 'radix-vue'
import { DragHandleDots2Icon } from '@radix-icons/vue'

const props = defineProps<SplitterResizeHandleProps & { class?: HTMLAttributes['class'], withHandle?: boolean }>()
const emits = defineEmits<SplitterResizeHandleEmits>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props
  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <SplitterResizeHandle v-bind="forwarded" :class="['splitter-resize-handle', props.class]">
    <template v-if="props.withHandle">
      <div class="handle-container">
        <DragHandleDots2Icon class="handle-icon" />
      </div>
    </template>
  </SplitterResizeHandle>
</template>

<style scoped>
.splitter-resize-handle {
  position: relative;
  display: flex;
  width: 1px;
  align-items: center;
  justify-content: center;
  background-color: hsl(var(--border));
}

.splitter-resize-handle::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 4px;
  transform: translateX(-50%);
}

.splitter-resize-handle:focus-visible {
  outline: none;
  ring: 1px solid var(--ring-color);
  ring-offset: 1px;
}

.splitter-resize-handle[data-orientation="vertical"] {
  height: 1px;
  width: 100%;
}

.splitter-resize-handle[data-orientation="vertical"]::after {
  left: 0;
  height: 4px;
  width: 100%;
  transform: translateY(-50%);
}

.splitter-resize-handle[data-orientation="vertical"] > div {
  transform: rotate(90deg);
}

.handle-container {
  z-index: 10;
  display: flex;
  height: 16px;
  width: 12px;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: 1px solid hsl(var(--border));
  background-color: hsl(var(--border));
}

.handle-icon {
  height: 10px;
  width: 10px;
}
</style>