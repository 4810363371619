<script async setup lang="ts">
import { Icon } from '@iconify/vue';
import { ref } from '@vue/reactivity';
import { addAttachment } from '@app/state/solicitation';
import { computed } from 'vue';
import Button from '@app/components/ui/button/Button.vue';
import Paragraph from '@app/components/typography/Paragraph.vue';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogClose,
  DialogTitle,
  DialogTrigger,
} from "@app/components/ui/dialog"
import { DropdownMenuItem } from '@app/components/ui/dropdown-menu';

const fileInput = ref<HTMLInputElement>()
const files = ref<File[]>([])
const handleFileUpload = (event: Event) => {
  const target = event.target as HTMLInputElement
  files.value = Array.from(target.files ?? [])
}
const invalid = computed(() => files.value.filter(file => file.size > 200 * 1024 * 1024))

const create = () => {
  addAttachment(files.value[0])
  resetUploadFlow()
}

const resetUploadFlow = () => {
  files.value = []
  if (fileInput.value) {
    fileInput.value.value = ''
  }
}
</script>

<template>
  <Dialog>
    <DialogTrigger>
      <DropdownMenuItem @select.prevent>
        <Icon icon="lucide:paperclip" class="mr-2" />
        Add Attachment
      </DropdownMenuItem>
    </DialogTrigger>
    <DialogContent>
      <DialogHeader>
        <DialogTitle v-if="files.length <= 0">Upload Attachment</DialogTitle>
        <DialogTitle v-if="files.length > 0">Confirm Upload</DialogTitle>
        <DialogDescription>
          <Paragraph v-if="files.length <= 0">ProposalAI works best with <b>PDFs</b>, but supports Word, Powerpoint, Excel, images, audio, and text.<br>Please upload one file at a time.</Paragraph>

          <input ref="fileInput" type="file" style="display: none" @change="handleFileUpload" />

          <div v-if="files.length > 0">
            You have selected <b>{{ files.length }}</b> file(s), and
            <b>{{ (files.reduce((acc, file) => acc + file.size, 0) / 1024 / 1024).toFixed(2) }}
              MB</b>
            will be uploaded.
          </div>
          <div v-if="invalid.length > 0">
            <br>
            However, <b>{{ invalid.length }}</b> of them will
            be ignored as they are <b>too large</b>, please stay under <b>200MB</b>.
          </div>
          <br>
          <Button variant="default" @click="fileInput!.click()" v-if="files.length <= 0">
            <template #icon>
              <Icon icon="lucide:upload" />
            </template>
            Upload a File
          </Button>

          <DialogClose v-if="files.length > 0 && files.length > invalid.length">
            <Button variant="default" @click="create">
              <template #icon>
                <Icon icon="lucide:check" />
              </template>
              Add Attachment
            </Button>
          </DialogClose>

        </DialogDescription>
      </DialogHeader>
    </DialogContent>
  </Dialog>
</template>

<style lang="scss" scoped>
b {
  color: hsl(var(--foreground)) !important;
}
</style>