<template>
  <p :class="['card-description', props.class]">
    <slot />
  </p>
</template>

<script setup lang="ts">
import type { HTMLAttributes } from 'vue'

const props = defineProps<{
  class?: HTMLAttributes['class']
}>()
</script>

<style scoped>
.card-description {
  font-size: var(--text-sm);
  color: hsl(var(--muted-foreground));
}

</style>