<script async setup lang="ts">
import type { SolutioningItem } from '@Heirloom/common';
import EditableText from '@app/components/common/EditableText.vue';
import { deleteSolutioning, updateSolutioning } from '@app/state/solutioning';
import { Card } from '@app/components/ui/card';
import Rows from '@app/components/ui/flex/Rows.vue';
import Large from '@app/components/typography/Large.vue';
import Muted from '@app/components/typography/Muted.vue';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
  ContextMenuShortcut,
} from '@app/components/ui/context-menu'
import { Icon } from '@iconify/vue'

const props = defineProps<{
  item: SolutioningItem
}>()

const doUpdate = () => updateSolutioning(props.item.id, props.item.question, props.item.answer ?? '')
const doDelete = () => deleteSolutioning(props.item.id)
</script>

<template>
  <ContextMenu>
    <ContextMenuTrigger>
      <Rows margin="15px 0 0 0">
        <Card>
      <Rows padding="15px 10px">
        <Rows padding="0 5px">
          <Large>
            <EditableText v-model="item.question" @submit="doUpdate" />
          </Large>
        </Rows>
        <Rows margin="5px 0 0 0">
          <Muted>
            <EditableText v-model="item.answer" multiline @submit="doUpdate" />
          </Muted>
        </Rows>
      </Rows>
        </Card>
      </Rows>
    </ContextMenuTrigger>
    <ContextMenuContent>
      <ContextMenuItem @click="doDelete">
        Delete
        <ContextMenuShortcut>
          <Icon icon="lucide:trash" />
        </ContextMenuShortcut>
      </ContextMenuItem>
    </ContextMenuContent>
  </ContextMenu>
</template>

<style scoped lang="scss"></style>