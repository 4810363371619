import { sock, BIND } from "@app/state/common"
import { ref } from "@vue/reactivity"
import type { StackTenant } from "@Heirloom/common"
import Logger from "@app/utils/logger"
const Log = new Logger("Tenant", { bgColor: '#000000', fgColor: '#ffffff' })

export const createTenant = async (name: string, slug: string) => {
  Log.info("Creating tenant:", name, '|', slug)
  sock.pub("tenant/create", { name, slug })
}
export const listTenants = async () => sock.pub("tenant/list", {})

export const tenants = ref<StackTenant[]>([])

export const escalate = () => {
  BIND('△:tenant', () => listTenants)
  BIND('tenant:list', () => ts => {
    tenants.value = [...ts]
    Log.success("Updated tenant list.")
  })
}
