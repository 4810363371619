<script async setup lang="ts">
import {
  Table as T,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "@app/components/ui/table"
import { ComplianceItem } from "@Heirloom/common";
import Item from '@app/components/compliance/Item.vue';

const props = defineProps<{
  items: ComplianceItem[]
}>()
</script>

<template>
  <T>
    <TableHeader>
      <TableRow>
        <TableHead>#</TableHead>
        <TableHead class="w-[500px]">Compliance Item</TableHead>
        <TableHead class="w-[200px]">Referenced in</TableHead>
        <TableHead class="w-[200px]">Addressed in</TableHead>
        <TableHead class="w-[500px]">Summary</TableHead>
      </TableRow>
    </TableHeader>
    <TableBody>
      <Item v-for="(item, i) in props.items" :item="item" :order="i" />
    </TableBody>
  </T>
</template>
