import activityheartbeat from "@app/assets/icons/activity-heartbeat.svg"
import aligncenter from "@app/assets/icons/align-center.svg"
import alignleft from "@app/assets/icons/align-left.svg"
import alignright from "@app/assets/icons/align-right.svg"
import airconditioning from "@app/assets/icons/air-conditioning.svg"
import ar from "@app/assets/icons/ar.svg"
import archive from "@app/assets/icons/archive.svg"
import arrowbigright from "@app/assets/icons/arrow-big-right.svg"
import attachment from "@app/assets/icons/attachment.svg"
import backhoe from "@app/assets/icons/backhoe.svg"
import bcc from "@app/assets/icons/bcc.svg"
import billing from "@app/assets/icons/billing.svg"
import board from "@app/assets/icons/board.svg"
import bold from "@app/assets/icons/bold.svg"
import brandGoogle from "@app/assets/icons/brand-google.svg"
import brandMicrosoft from "@app/assets/icons/brand-microsoft.svg"
import bug from "@app/assets/icons/bug.svg"
import calendar from "@app/assets/icons/calendar.svg"
import calendly from "@app/assets/icons/calendly.svg"
import cellsignal5 from "@app/assets/icons/cell-signal-5.svg"
import check from "@app/assets/icons/check.svg"
import chevrondown from "@app/assets/icons/chevron-down.svg"
import chevronright from "@app/assets/icons/chevron-right.svg"
import chevronup from "@app/assets/icons/chevron-up.svg"
import cooker from "@app/assets/icons/cooker.svg"
import disconnected from "@app/assets/icons/plug-connected-x.svg"
import dnumber1 from "@app/assets/icons/circle-dashed-number-1.svg"
import dnumber2 from "@app/assets/icons/circle-dashed-number-2.svg"
import dnumber3 from "@app/assets/icons/circle-dashed-number-3.svg"
import dnumber4 from "@app/assets/icons/circle-dashed-number-4.svg"
import dnumber5 from "@app/assets/icons/circle-dashed-number-5.svg"
import dnumber6 from "@app/assets/icons/circle-dashed-number-6.svg"
import dnumber7 from "@app/assets/icons/circle-dashed-number-7.svg"
import dnumber8 from "@app/assets/icons/circle-dashed-number-8.svg"
import dnumber9 from "@app/assets/icons/circle-dashed-number-9.svg"
import number1 from "@app/assets/icons/circle-number-1.svg"
import number2 from "@app/assets/icons/circle-number-2.svg"
import number3 from "@app/assets/icons/circle-number-3.svg"
import number4 from "@app/assets/icons/circle-number-4.svg"
import number5 from "@app/assets/icons/circle-number-5.svg"
import number6 from "@app/assets/icons/circle-number-6.svg"
import number7 from "@app/assets/icons/circle-number-7.svg"
import number8 from "@app/assets/icons/circle-number-8.svg"
import number9 from "@app/assets/icons/circle-number-9.svg"
import circle from "@app/assets/icons/circle.svg"
import clipboardtext from "@app/assets/icons/clipboard-text.svg"
import close from "@app/assets/icons/close.svg"
import cloudupload from "@app/assets/icons/cloud-upload.svg"
import code from "@app/assets/icons/code.svg"
import compose from "@app/assets/icons/compose.svg"
import copy from "@app/assets/icons/copy.svg"
import danger from "@app/assets/icons/danger.svg"
import documentation from "@app/assets/icons/documentation.svg"
import dots from "@app/assets/icons/dots.svg"
import down from "@app/assets/icons/down.svg"
import download from "@app/assets/icons/download.svg"
import drafts from "@app/assets/icons/drafts.svg"
import drag from "@app/assets/icons/drag.svg"
import filter from "@app/assets/icons/filter.svg"
import flame from "@app/assets/icons/flame.svg"
import forward from "@app/assets/icons/forward.svg"
import history from "@app/assets/icons/history.svg"
import home from "@app/assets/icons/home.svg"
import image from "@app/assets/icons/image.svg"
import info from "@app/assets/icons/info.svg"
import integrations from "@app/assets/icons/integrations.svg"
import italic from "@app/assets/icons/italic.svg"
import lifebuoy from "@app/assets/icons/lifebuoy.svg"
import link from "@app/assets/icons/link.svg"
import listcheck from "@app/assets/icons/list-check.svg"
import listnumbers from "@app/assets/icons/list-numbers.svg"
import list from "@app/assets/icons/list.svg"
// @ts-ignore
import logo from "@app/assets/img/logo.png"
import mail from "@app/assets/icons/mail.svg"
import microphone from "@app/assets/icons/microphone.svg"
import moodsmile from "@app/assets/icons/mood-smile.svg"
import pencil from "@app/assets/icons/pencil.svg"
import plus from "@app/assets/icons/plus.svg"
import print from "@app/assets/icons/print.svg"
import quote from "@app/assets/icons/quote.svg"
import refresh from "@app/assets/icons/refresh.svg"
import replyAll from "@app/assets/icons/reply-all.svg"
import reply from "@app/assets/icons/reply.svg"
import receipt from "@app/assets/icons/receipt.svg"
import rocket from "@app/assets/icons/rocket.svg"
import roundedx from "@app/assets/icons/roundedx.svg"
import scribe from "@app/assets/icons/scribe.svg"
import search from "@app/assets/icons/search.svg"
import send from "@app/assets/icons/send.svg"
import sent from "@app/assets/icons/sent.svg"
import settings from "@app/assets/icons/settings.svg"
import settings2 from "@app/assets/icons/settings2.svg"
import sidebarCollapse from "@app/assets/icons/sidebar-collapse.svg"
import signature from "@app/assets/icons/signature.svg"
import snowflake from "@app/assets/icons/snowflake.svg"
import spam from "@app/assets/icons/spam.svg"
import squarerounded from "@app/assets/icons/square-rounded.svg"
import star from "@app/assets/icons/star.svg"
import starred from "@app/assets/icons/starred.svg"
import startRecord from "@app/assets/icons/start-record.svg"
import steam from "@app/assets/icons/steam.svg"
import team from "@app/assets/icons/team.svg"
import templates from "@app/assets/icons/templates.svg"
import textcolor from "@app/assets/icons/text-color.svg"
import thread from "@app/assets/icons/thread.svg"
import tracker from "@app/assets/icons/tracker.svg"
import trash from "@app/assets/icons/trash.svg"
import trees from "@app/assets/icons/trees.svg"
import underline from "@app/assets/icons/underline.svg"
import unsubscribe from "@app/assets/icons/unsubscribe.svg"
import userCircle from "@app/assets/icons/user-circle.svg"
import user from "@app/assets/icons/user.svg"
import x from "@app/assets/icons/x.svg"
import zap from "@app/assets/icons/zap.svg"
import zoom from "@app/assets/icons/zoom.svg"
import writing from "@app/assets/icons/writing.svg"
import license from "@app/assets/icons/license.svg"

//! FIXME: this should always be in alphabetical order


export default {
	"activity-heartbeat": activityheartbeat,
	"align-center": aligncenter,
	"align-left": alignleft,
	"align-right": alignright,
	"air-conditioning": airconditioning,
	"ar": ar,
	"archive": archive,
	"arrow-big-right": arrowbigright,
	"attachment": attachment,
	"backhoe": backhoe,
	"bcc": bcc,
	"billing": billing,
	"board": board,
	"bold": bold,
	"brand-google": brandGoogle,
	"brand-microsoft": brandMicrosoft,
	"bug": bug,
	"calendar": calendar,
	"calendly": calendly,
	"cell-signal-5": cellsignal5,
	"check": check,
	"chevron-down": chevrondown,
	"chevron-right": chevronright,
	"chevron-up": chevronup,
	"circle": circle,
	"clipboard:": clipboardtext,
	"close": close,
	"cloud-upload": cloudupload,
	"cooker": cooker,
	"code": code,
	"compose": compose,
	"copy": copy,
	"danger": danger,
	"disconnected": disconnected,
	"dnumber1": dnumber1,
	"dnumber2": dnumber2,
	"dnumber3": dnumber3,
	"dnumber4": dnumber4,
	"dnumber5": dnumber5,
	"dnumber6": dnumber6,
	"dnumber7": dnumber7,
	"dnumber8": dnumber8,
	"dnumber9": dnumber9,
	"documentation": documentation,
	"dots": dots,
	"down": down,
	"download": download,
	"drafts": drafts,
	"drag": drag,
	"filter": filter,
	"flame": flame,
	"forward": forward,
	"history": history,
	"home": home,
	"image": image,
	"info": info,
	"integrations": integrations,
	"italic": italic,
	"license": license,
	"lifebuoy": lifebuoy,
	"link": link,
	"list-check": listcheck,
	"list-numbers": listnumbers,
	"list": list,
	"logo": logo,
	"mail": mail,
	"microphone": microphone,
	"mood-smile": moodsmile,
	"number1": number1,
	"number2": number2,
	"number3": number3,
	"number4": number4,
	"number5": number5,
	"number6": number6,
	"number7": number7,
	"number8": number8,
	"number9": number9,
	"pencil": pencil,
	"plus": plus,
	"print": print,
	"quote": quote,
	"receipt": receipt,
	"refresh": refresh,
	"reply-all": replyAll,
	"reply": reply,
	"rocket": rocket,
	"roundedx": roundedx,
	"scribe": scribe,
	"search": search,
	"send": send,
	"sent": sent,
	"settings": settings,
	"settings2": settings2,
	"sidebar-collapse": sidebarCollapse,
	"signature": signature,
	"snowflake": snowflake,
	"spam": spam,
	"square-rounded": squarerounded,
	"star": star,
	"starred": starred,
	"start-record": startRecord,
	"steam": steam,
	"team": team,
	"templates": templates,
	"text-color": textcolor,
	"thread": thread,
	"tracker": tracker,
	"trash": trash,
	"trees": trees,
	"underline": underline,
	"unsubscribe": unsubscribe,
	"user-circle": userCircle,
	"user": user,
	"writing": writing,
	"x": x,
	"zap": zap,
	"zoom": zoom,
}