<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import {
  DropdownMenuItemIndicator,
  DropdownMenuRadioItem,
  type DropdownMenuRadioItemEmits,
  type DropdownMenuRadioItemProps,
  useForwardPropsEmits,
} from 'radix-vue'
import { DotFilledIcon } from '@radix-icons/vue'

const props = defineProps<DropdownMenuRadioItemProps & { class?: HTMLAttributes['class'] }>()
const emits = defineEmits<DropdownMenuRadioItemEmits>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props
  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <DropdownMenuRadioItem
    v-bind="forwarded"
    :class="['dropdown-menu-radio-item', props.class]"
  >
    <span class="indicator-container">
      <DropdownMenuItemIndicator>
        <DotFilledIcon class="dot-icon" />
      </DropdownMenuItemIndicator>
    </span>
    <slot />
  </DropdownMenuRadioItem>
</template>

<style scoped>
.dropdown-menu-radio-item {
  position: relative;
  display: flex;
  cursor: default;
  user-select: none;
  align-items: center;
  border-radius: 0.125rem;
  padding: 0.375rem 0.5rem 0.375rem 2rem;
  font-size: var(--text-sm);
  outline: none;
  transition: background-color 0.2s, color 0.2s;
}

.dropdown-menu-radio-item:focus {
  background-color: hsl(var(--muted));
  color: hsl(var(--foreground));
}

.dropdown-menu-radio-item[data-disabled] {
  pointer-events: none;
  opacity: 0.5;
}

.indicator-container {
  position: absolute;
  left: 0.5rem;
  display: flex;
  height: 0.875rem;
  width: 0.875rem;
  align-items: center;
  justify-content: center;
}

.dot-icon {
  height: 1rem;
  width: 1rem;
  fill: currentColor;
}
</style>