<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import { cn } from '@app/utils/tailwind'

const props = defineProps<{
  class?: HTMLAttributes['class']
}>()
</script>

<template>
  <div :class="['alert-dialog-description', props.class]">
    <slot />
  </div>
</template>

<style scoped>
.alert-dialog-description {
  font-size: var(--text-sm);
}

.alert-dialog-description p {
  line-height: 1.625;
}
</style>