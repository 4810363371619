<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import { type AlertVariants, alertVariants } from '.'
import { cn } from '@app/utils/tailwind'

const props = defineProps<{
  class?: HTMLAttributes['class']
  variant?: AlertVariants['variant']
}>()
</script>

<template>
  <div :class="'destructive ' + cn(alertVariants({ variant }), props.class)" role="alert">
    <slot />
  </div>
</template>

<style scoped>
.destructive {
  background-color: hsl(0deg 100% 98.21%)
}
@media (prefers-color-scheme: dark) {
  .destructive {
    background-color: hsl(0deg 100% 6.21%)
  }
}
</style>