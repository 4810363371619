<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import type { ComboboxEmptyProps } from 'radix-vue'
import { ComboboxEmpty } from 'radix-vue'

const props = defineProps<ComboboxEmptyProps & { class?: HTMLAttributes['class'] }>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})
</script>

<template>
  <ComboboxEmpty v-bind="delegatedProps" :class="['combobox-empty', props.class]">
    <slot />
  </ComboboxEmpty>
</template>

<style scoped>
.combobox-empty {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  text-align: center;
  font-size: var(--text-sm);
}
</style>