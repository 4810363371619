<script async setup lang="ts">
import { watch } from 'vue';
import { ref } from '@vue/reactivity';
import type { Maybe } from '@Heirloom/common';
import { Badge } from '@app/components/ui/badge';
import { Icon } from '@iconify/vue'
import { Card } from '@app/components/ui/card';
import { getSolicitationPage, solicitationPage } from '@app/state/solicitation';
import Rows from '@app/components/ui/flex/Rows.vue';
import Columns from '@app/components/ui/flex/Columns.vue';
import { Button } from '@app/components/ui/button';
import Large from '@app/components/typography/Large.vue';
import {
  ContextMenu,
  ContextMenuTrigger,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuShortcut,
} from '@app/components/ui/context-menu';
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from '@app/components/ui/collapsible';
import Markdown from '@app/components/common/Markdown.vue';

const props = defineProps<{
  page: {
    id: string
    pageNumber: number
    markdown: Maybe<string>
    tags: string[]
  }
  total: number
  solicitationId: string
}>()

const open = ref(true)
const showImage = ref(false)
const imageLoaded = ref(false)
const currentPageId = ref<string | null>(null)

const loadImage = async () => {
  currentPageId.value = props.page.id
  imageLoaded.value = false
  if (solicitationPage.value?.id !== props.page.id) {
    await getSolicitationPage(props.page.id)
  }
  if (solicitationPage.value?.image) {
    imageLoaded.value = true
  }
}

const toggleImage = async () => {
  if (!showImage.value) {
    await loadImage()
  }
  showImage.value = !showImage.value
}

watch(() => solicitationPage.value?.image, () => {
  if (solicitationPage.value?.id === props.page.id) {
    imageLoaded.value = true
  }
})

watch(() => props.page.id, () => {
  if (currentPageId.value !== props.page.id) {
    imageLoaded.value = false
  }
})
</script>

<template>
  <ContextMenu>
    <ContextMenuTrigger>
      <Card>
        <Columns padding="20px">
          <Collapsible v-model:open="open" type="single">
            <Columns flex="1">
              <CollapsibleTrigger :key="page.id" class="text-left">
                <Columns spread center>
                  <Columns flex="1" gap="10">
                    Page {{ page.pageNumber + 1 }} / {{ total }}
                    <Badge v-for="tag in page.tags" :key="tag">{{ tag }}</Badge>
                  </Columns>
                  <Columns width="30px">
                    <Button variant="ghost" size="icon" @click.stop.prevent="toggleImage">
                      <Icon :icon="showImage ? 'lucide:letter-text' : 'lucide:image'" />
                    </Button>
                  </Columns>
                </Columns>
              </CollapsibleTrigger>
            </Columns>
            <CollapsibleContent :value="page.id">
              <Rows v-if="showImage">
                <Columns gap="20">
                  <Rows flex="1">
                    <Markdown :markdown="page.markdown ?? ''" />
                  </Rows>
                  <Rows flex="1">
                    <template v-if="currentPageId === page.id">
                      <img v-if="imageLoaded && solicitationPage?.id === page.id" class="page-img"
                        :src="solicitationPage.image" crossorigin="anonymous" />
                    </template>
                  </Rows>
                </Columns>
              </Rows>
              <Markdown v-else :markdown="page.markdown ?? ''" />
            </CollapsibleContent>
          </Collapsible>
        </Columns>
      </Card>
    </ContextMenuTrigger>
    <ContextMenuContent>
      <ContextMenuItem>
        <span>Copy</span>
        <ContextMenuShortcut>
          <Icon icon="lucide:copy" />
        </ContextMenuShortcut>
      </ContextMenuItem>
    </ContextMenuContent>
  </ContextMenu>
</template>

<style scoped lang="scss">
.markdown-content {
  flex: 1;
  min-width: 0;
}

.image-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
}

.page-img {
  width: 100%;
  height: auto;
  background-color: white;
  border-radius: 5px;
}

.markdown {
  background-color: var(--secondary-background-color);
  border-radius: 8px;
  box-shadow: 0 0 0 1px var(--primary-background-color);
  padding: 12px;

  pre {
    color: var(--strong-font-color);
    font-size: 14px;
    line-height: 1.5;
    white-space: pre-wrap;
  }
}

.section {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: var(--primary-color);
    user-select: none;

    img {
      margin-top: -2px;
      margin-right: 6px;
    }

    .page-tag {
      text-transform: uppercase;
      height: 20px;
      top: -1px;
      margin-left: 8px;
      position: relative;
      color: var(--green);
      background-color: var(--green-bg);
      user-select: none;
    }
  }
}

.loader {
  margin-top: 140px;
}
</style>