<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import { ContextMenuLabel, type ContextMenuLabelProps } from 'radix-vue'
import { Icon } from '@iconify/vue'

const props = defineProps<ContextMenuLabelProps & { class?: HTMLAttributes['class'], inset?: boolean }>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})
</script>

<template>
  <ContextMenuLabel
    v-bind="delegatedProps"
    :class="['context-menu-label', { 'inset': inset }, props.class]"
  >
    <slot />
  </ContextMenuLabel>
</template>

<style scoped>
.context-menu-label {
  padding: 0.375rem 0.5rem;
  font-size: var(--text-sm);
  font-weight: 600;
  color: hsl(var(--foreground));
}

.context-menu-label.inset {
  padding-left: 2rem;
}
</style>